import React, { useRef, useEffect, useState } from "react";
import mapboxgl, { GeolocateControl } from "mapbox-gl";
import Filter from "./FilterComponent";
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchData } from "../services/companies/poi.service";
import Popup from "./PopupComponent";
import { getColorForPhase, getLabelForPhase } from '../helpers/utils';
import { getGeolocationError } from '../services/geocoding/geocoding.service'
import CreateCompany from '../components/CreateCompany';
import { fetchAndStoreCompanies as fetchAndStoreLocalyCompanies } from '../dataaccess/db';
import { deleteCompany } from '../services/companies/poi.service';
import { Provider, useDispatch } from 'react-redux';
import { store } from '../redux/store';
import { alertActions } from '../redux/actions';
import { isNullOrEmpty } from '../helpers/utils';
import Draggable from 'react-draggable';
import { Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const { REACT_APP_REFRESH_INTERVAL, REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

//Local parameters
const SOURCE = "points-data";
const CIRCLE_RADIUS = 12;
const SPIDERIFY_AFTER_ZOOM = 20; // Spiderify after zoom N, zoom otherwise
const SPIDER_TYPE = "layer"; // marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
const MAX_LEAVES_TO_SPIDERIFY = 255; // Max leave to display when spiderify to prevent filling the map with leaves
const CIRCLE_TO_SPIRAL_SWITCHOVER = SPIDER_TYPE.toLowerCase() === "marker" ? 10 : 15; // When below number, will display leave as a circle. Over, as a spiral
const CIRCLE_OPTIONS = { distanceBetweenPoints: 75 };
const SPIRAL_OPTIONS = {
  rotationsModifier: 1250, // Higher modifier = closer spiral lines
  distanceBetweenPoints: SPIDER_TYPE.toLowerCase() === "marker" ? 42 : 32, // Distance between points in spiral
  radiusModifier: 50000, // Spiral radius
  lengthModifier: 1000 // Spiral length modifier
};
const SPIDER_LEGS = true;
const POPUP = {
  width: 400,
  height: 400
}

//
const Map = ({ darkMode }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [filters, setFilters] = useState(() => ({}));

  const [tempFilters, setTempFilters] = useState(filters);
  const [filterOpen, setFilterOpen] = useState(false);
  const [map, setMap] = useState(null);
  const dispatch = useDispatch();
  const [popupPosition, setPopupPosition] = useState(null);
  const [clickedFeature, setClickedFeature] = useState(null);

  //
  const location = useLocation();

  const areFiltersApplied = () => {
    return filters.cycles?.length > 0 || filters.companyNames?.length > 0 || filters.companyOwners?.length > 0
  };

  const updateMapData = async (filters) => {

    const map = mapRef.current;
    if (!map) return;

    const { lng, lat } = map.getCenter();
    const results = await fetchData({
      longitude: lng,
      latitude: lat,
      filters: filters
    });
    results.features.forEach(feature => {
      feature.properties.color = getColorForPhase(feature.properties['lifecyclestage']);
      feature.properties.lifecyclestageLabel = "Cycle : " + getLabelForPhase(feature.properties['lifecyclestage']);
      feature.properties.numberOfvisit = "Nombre de visites : " + (feature.properties['nombre_de_visite'] != null ? feature.properties['nombre_de_visite'] : 0);
      feature.properties.ownerName = "Propriétaire : " + !isNullOrEmpty(feature.properties['hubspot_owner_name']) ? feature.properties['hubspot_owner_name'] : "Non renseigné";
    });

    try {
      if (map && map.getSource && map.getSource(SOURCE)) {
        const offsetData = {
          type: 'FeatureCollection',
          features: results.features,
        };
        map.getSource(SOURCE).setData(offsetData);
      } else {
        console.error('Map or source is undefined. Cannot set data.');
      }
    } catch (error) {
      console.error('An error occurred while updating the map source:', error);
    }
  };

  const handleClosePopup = () => {
    setPopupPosition(null);
    setClickedFeature(null);
  };

  const SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  const SPIDER_LEGS_PAINT_OPTION = {
    "line-width": 2,
    "line-color": "rgba(128, 128, 128, 0.5)"
  };

  const SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  const SPIDER_LEAVES_LABELS = `spider-leaves-labels-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  const SPIDER_LEAVES_PAINT_OPTION = {
    "circle-color": ["get", "color"],
    "circle-radius": CIRCLE_RADIUS,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff"
  };

  let clusterMarkers = [];
  let spiderifiedCluster = {};
  let spiderLeavesCollection = [];
  let points = [];

  const clearSpierifiedMarkers = () => {
    if (clusterMarkers.length > 0) {
      for (let i = 0; i < clusterMarkers.length; i++) {
        clusterMarkers[i].remove();
      }
    }
    clusterMarkers = [];
  }

  const removeSourceAndLayer = (_map, id) => {
    if (_map.getLayer(id) != null) _map.removeLayer(id);
    if (_map.getSource(id) != null) _map.removeSource(id);
  }

  const clearSpiderifiedCluster = () => {
    spiderifiedCluster = {};
    spiderLeavesCollection = [];
    if (mapRef) {
      removeSourceAndLayer(mapRef.current, SPIDER_LEGS_LAYER_NAME);
      removeSourceAndLayer(mapRef.current, SPIDER_LEAVES_LAYER_NAME);
      removeSourceAndLayer(mapRef.current, SPIDER_LEAVES_LABELS);
    }
    clearSpierifiedMarkers();
  }

  const generateEquidistantPointsInCircle = ({
    totalPoints = 1,
    options = CIRCLE_OPTIONS
  }) => {
    let points = [];
    let theta = (Math.PI * 2) / totalPoints;
    let angle = theta;
    for (let i = 0; i < totalPoints; i++) {
      angle = theta * i;
      points.push({
        x: options.distanceBetweenPoints * Math.cos(angle),
        y: options.distanceBetweenPoints * Math.sin(angle)
      });
    }
    return points;
  }

  const generateEquidistantPointsInSpiral = ({
    totalPoints = 10,
    options = SPIRAL_OPTIONS
  }) => {
    let points = [{ x: 0, y: 0 }];
    // Higher modifier = closer spiral lines
    const rotations = totalPoints * options.rotationsModifier;
    const distanceBetweenPoints = options.distanceBetweenPoints;
    const radius = totalPoints * options.radiusModifier;
    // Value of theta corresponding to end of last coil
    const thetaMax = rotations * 2 * Math.PI;
    // How far to step away from center for each side.
    const awayStep = radius / thetaMax;
    for (
      let theta = distanceBetweenPoints / awayStep;
      points.length <= totalPoints + options.lengthModifier;

    ) {
      points.push({
        x: Math.cos(theta) * (awayStep * theta),
        y: Math.sin(theta) * (awayStep * theta)
      });
      theta += distanceBetweenPoints / (awayStep * theta);
    }
    return points.slice(0, totalPoints);
  }

  const generateLeavesCoordinates = ({ nbOfLeaves }) => {
    // Position cluster's leaves in circle if below threshold, spiral otherwise
    if (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER) {
      points = generateEquidistantPointsInCircle({
        totalPoints: nbOfLeaves
      });
    } else {
      points = generateEquidistantPointsInSpiral({
        totalPoints: nbOfLeaves
      });
    }
    return points;
  }

  const spiderifyCluster = ({ map, source, clusterToSpiderify }) => {
    let spiderlegsCollection = [];
    let spiderLeavesCollection = [];

    map
      .getSource(source)
      .getClusterLeaves(
        clusterToSpiderify.id,
        MAX_LEAVES_TO_SPIDERIFY,
        0,
        (error, features) => {
          if (error) {
            console.warning("Cluster does not exists on this zoom");
            return;
          }

          let leavesCoordinates = generateLeavesCoordinates({
            nbOfLeaves: features.length
          });

          let clusterXY = map.project(clusterToSpiderify.coordinates);

          // Generate spiderlegs and leaves coordinates
          features.forEach((element, index) => {
            let spiderLeafLatLng = map.unproject([
              clusterXY.x + leavesCoordinates[index].x,
              clusterXY.y + leavesCoordinates[index].y
            ]);

            if (SPIDER_TYPE.toLowerCase() === "marker") {
              clusterMarkers.push(
                new mapboxgl.Marker().setLngLat(spiderLeafLatLng)
              );
            }
            if (SPIDER_TYPE.toLowerCase() === "layer") {
              spiderLeavesCollection.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                },
                properties: element.properties
              });
            }

            if (SPIDER_LEGS) {
              spiderlegsCollection.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    clusterToSpiderify.coordinates,
                    [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                  ]
                }
              });
            }
          });

          // Draw spiderlegs and leaves coordinates
          if (SPIDER_LEGS) {
            map.addLayer({
              id: SPIDER_LEGS_LAYER_NAME,
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderlegsCollection
                }
              },
              paint: SPIDER_LEGS_PAINT_OPTION
            });
          }

          if (SPIDER_TYPE.toLowerCase() === "marker") {
            clusterMarkers.forEach(marker => marker.addTo(map));
          }

          if (SPIDER_TYPE.toLowerCase() === "layer") {
            map.addLayer({
              id: SPIDER_LEAVES_LAYER_NAME,
              type: "circle",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderLeavesCollection
                }
              },
              paint: SPIDER_LEAVES_PAINT_OPTION
            });

            // Ajouter une couche pour afficher les labels sur toutes les feuilles
            map.addLayer({
              id: SPIDER_LEAVES_LABELS,
              type: "symbol",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderLeavesCollection
                }
              },
              layout: {
                "text-field": [
                  "concat",
                  ["get", "name"],
                  "\n",
                  ["get", "ownerName"],
                  "\n",
                  ["get", "lifecyclestageLabel"],
                  "\n",
                  ["get", "numberOfvisit"]
                ],
                "text-font": ["Poppins Regular", "Arial Unicode MS Regular"],
                "text-offset": [0, 1.25],
                "text-anchor": "top",
                "text-size": 10
              },
              paint: {
                "text-color": "#7B3F00",
                "text-halo-width": 0,
                "text-halo-blur": 0,
                "text-opacity": 1
              }
            });
          }
        }
      );
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            //style: "mapbox://styles/mapbox/streets-v11",
            style: darkMode ? "mapbox://styles/mapbox/dark-v10" : "mapbox://styles/mapbox/streets-v11",
            center: [longitude, latitude],
            zoom: 10,
            maxZoom: 20
          });

          const map = mapRef.current;

          setMap(map)

          map.addControl(new mapboxgl.NavigationControl(), "top-right");
          map.addControl(new GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true,
            showUserHeading: true
          }), "top-right");

          map.on("load", async () => {
            map.addSource(SOURCE, {
              type: "geojson",
              data: { type: "FeatureCollection", features: [] },
              cluster: true,
              clusterMaxZoom: SPIDERIFY_AFTER_ZOOM,
              clusterRadius: 50
            });

            map.addLayer({
              id: "clusters",
              type: "circle",
              source: SOURCE,
              filter: ["has", "point_count"],
              paint: {
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1"
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40
                ]
              }
            });

            map.addLayer({
              id: "cluster-count",
              type: "symbol",
              source: SOURCE,
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12
              }
            });

            map.addLayer({
              id: "unclustered-point",
              type: "circle",
              source: SOURCE,
              filter: ["!", ["has", "point_count"]],
              paint: {
                "circle-color": ["get", "color"],
                "circle-radius": CIRCLE_RADIUS,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#000000"
              }
            });

            map.addLayer({
              id: "poi-labels",
              type: "symbol",
              source: SOURCE,
              filter: ["!", ["has", "point_count"]],
              layout: {
                "text-field": ["concat", ["get", "name"], "\n", ["get", "ownerName"], "\n", ["get", "lifecyclestageLabel"], "\n", ["get", "numberOfvisit"]],
                "text-font": ["Poppins Regular", "Arial Unicode MS Regular"],
                "text-offset": [0, 1.25],
                "text-anchor": "top",
                "text-size": 10
              },
              paint: {
                "text-color": "#7B3F00",
                "text-halo-width": 0,
                "text-halo-blur": 0,
                "text-opacity": 1
              }
            });

            //
            updateMapData();

            // Events
            map.on("click", "clusters", (e) => {
              const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
              const clusterId = features[0].properties.cluster_id;
              // map.getSource(SOURCE).getClusterExpansionZoom(clusterId, (err, zoom) => {
              //   if (err) return;

              //   map.easeTo({
              //     center: features[0].geometry.coordinates,
              //     zoom: zoom
              //   });
              // });
              // Zoom on cluster or spiderify it
              if (map.getZoom() < SPIDERIFY_AFTER_ZOOM) {
                map
                  .getSource(SOURCE)
                  .getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;
                    map.easeTo({
                      center: features[0].geometry.coordinates,
                      zoom: zoom
                    });
                  });
              } else {
                spiderifiedCluster = { id: clusterId, coordinates: features[0].geometry.coordinates };
                spiderifyCluster({
                  map: map,
                  source: SOURCE,
                  clusterToSpiderify: spiderifiedCluster
                });
              }
            });

            map.on("click", e => {
              const features = map.queryRenderedFeatures(e.point);
              const isSpiderPoint = features.some(feature => feature.layer.id === SPIDER_LEAVES_LAYER_NAME);
              if (!isSpiderPoint) {
                clearSpiderifiedCluster();
              }
            });

            map.on("zoomstart", () => {
              clearSpiderifiedCluster();
            });

            // Add click event listener for spiderified points
            map.on('click', SPIDER_LEAVES_LAYER_NAME, (e) => {
              e.preventDefault();
              const feature = e.features[0];

              // Calculate the center position of the screen
              const centerX = (window.innerWidth / 2) - (POPUP.width / 2);
              const centerY = (window.innerHeight / 2) - (POPUP.height / 2);

              setClickedFeature(feature);
              setPopupPosition({ x: centerX, y: centerY }); // Set the popup position to the center of the screen
            });

            //
            map.on("click", "unclustered-point", (e) => {
              if (e.features.length) {
                const feature = e.features[0];

                // Calculate the center position of the screen
                const centerX = (window.innerWidth / 2) - (POPUP.width / 2);
                const centerY = (window.innerHeight / 2) - (POPUP.height / 2);

                setClickedFeature(feature);
                setPopupPosition({ x: centerX, y: centerY }); // Set the popup position to the center of the screen

                // Recenter the map to the clicked point if needed
                map.flyTo({
                  center: feature.geometry.coordinates,
                  zoom: 20, // Adjust the zoom level as needed
                  speed: 1.5 // Adjust the speed if necessary
                });
              }
            });

            //
            const queryParams = new URLSearchParams(location.search);
            const q = queryParams.get('q');
            if (q != null) {
              const res = await fetchData({ id: q });
              const featureToFind = res.features[0];
              removeQueryParam('q');
              if (featureToFind) {
                const centerX = (window.innerWidth / 2) - (POPUP.width / 2);
                const centerY = (window.innerHeight / 2) - (POPUP.height / 2);

                setClickedFeature(featureToFind);
                setPopupPosition({ x: centerX, y: centerY });

                map.flyTo({
                  center: featureToFind.geometry.coordinates,
                  zoom: 20,
                  speed: 1.5
                });
              }
            }

            map.on("mouseenter", "clusters", () => map.getCanvas().style.cursor = "pointer");
            map.on("mouseleave", "clusters", () => map.getCanvas().style.cursor = "");
            map.on("mouseenter", "unclustered-point", () => {
              map.getCanvas().style.cursor = "pointer";
            });
            map.on("mouseleave", "unclustered-point", () => {
              map.getCanvas().style.cursor = "";
            });
            map.on('zoom', () => {
              const zoomLevel = map.getZoom();
              if (zoomLevel >= 16) {
                map.setLayoutProperty('poi-labels', 'visibility', 'visible');
              } else {
                map.setLayoutProperty('poi-labels', 'visibility', 'none');
              }
            });

          });
        },
        (error) => {
          let msg = getGeolocationError(error);
          dispatch(alertActions.error(msg));
        }
      );
    } else {
      // Initialiser la carte avec un centre par défaut si la géolocalisation n'est pas disponible
    }

    return () => mapRef && mapRef.current && mapRef.current.remove();
  }, []);

  useEffect(() => {
    updateMapData(filters);

    const intervalId = setInterval(() => {
      updateMapData(filters);
    }, REACT_APP_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [filters]);

  const handleTempFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleFilterApply = () => {
    setFilters(tempFilters);
    setFilterOpen(false);
  };

  const handleFilterClear = () => {
    const clearedFilters = {

    };
    setFilters(clearedFilters);
    setTempFilters(clearedFilters);
    setFilterOpen(false);
  };

  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const handleUpdate = async () => {
    clearSpiderifiedCluster();
    await fetchAndStoreLocalyCompanies();
    updateMapData(filters);
  }

  const handleDelete = async (id) => {
    await deleteCompany(id).then(
      () => {
        handleClosePopup();
        clearSpiderifiedCluster();
      }
    )
  }

  const handleBackToHome = () => {
    navigate("/home")
  }

  // Fonction pour supprimer un paramètre de l'URL
  const removeQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(param); // Supprimer le paramètre 'q'
    const newUrl = `${location.pathname}?${queryParams.toString()}`; // Nouvelle URL sans le paramètre 'q'

    // Mettre à jour l'URL sans recharger la page
    navigate(newUrl, { replace: true }); // Utilisez navigate avec replace: true pour modifier l'URL sans ajouter une nouvelle entrée dans l'historique
  };

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
      {map &&
        <div className="map-controls">
          <Filter
            filters={tempFilters} // Utiliser les filtres temporaires ici
            filterOpen={filterOpen}
            handleFilterOpen={handleFilterOpen}
            handleFilterClose={handleFilterClose}
            handleFilterChange={handleTempFilterChange}
            isFilterActive={areFiltersApplied()}
            handleFilterApply={handleFilterApply}
            handleFilterClear={handleFilterClear}
            backToHome={handleBackToHome}
          />
        </div>
      }

      {map &&
        <CreateCompany handleAfterCreate={handleUpdate} map={map} />
      }

      {popupPosition && clickedFeature && (
        <Draggable handle=".draggable-handle">
          <Paper
            elevation={3}
            className="popup-container"
            style={{
              position: 'absolute',
              left: popupPosition.x,
              top: popupPosition.y,
              padding: "10px",
              zIndex: 1000,
              maxWidth: POPUP.width,
              width: POPUP.width,
              cursor: 'move',
            }}
          >

            {(() => {
              const lifecyclestage = clickedFeature.properties["lifecyclestage"];
              const dateConversion = new Date(clickedFeature.properties["date_de_conversion_en_accord"]);
              const today = new Date();
              const diffDays = Math.floor((today - dateConversion) / (1000 * 60 * 60 * 24));

              // Background color is fixed
              //  const backgroundColor = '#f1f1f1';

              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                    borderRadius: "5px 5px 0 0",
                    // backgroundColor: backgroundColor
                  }}
                >
                  <div className="draggable-handle" >
                    <Typography
                      variant="subtitle1"
                      style={{
                        cursor: 'move',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4px 0',
                        //backgroundColor: backgroundColor
                      }}
                    >
                      <span style={{ margin: 0 }}>
                        <strong>{clickedFeature.properties["name"]}</strong>
                      </span>
                    </Typography>
                  </div>
                  <div style={{ pointerEvents: 'auto' }}>
                    <IconButton size="small" onClick={handleClosePopup}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })()}


            <Provider store={store}>
              <Popup feature={clickedFeature} handleAfterDelete={handleDelete} handleAfterUpdate={handleUpdate} />
            </Provider>
          </Paper>
        </Draggable>
      )}

    </div>
  );
};

export default Map;
