import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import EuroIcon from '@mui/icons-material/Euro';

const SummaryInvoices = ({ invoicesList }) => {
    const [open, setOpen] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [popupContent, setPopupContent] = useState({
        data: {},
        invoices: []
    });

    useEffect(() => {
        if (Array.isArray(invoicesList)) {
            setInvoices(invoicesList);
        }
    }, [invoicesList]);


    const calculateInvoiceStats = () => {
        let unpaidCount = 0;
        let paidCount = 0;
        let unpaidTotal = 0;
        let paidTotal = 0;

        // Vérifier si invoices est défini et est un tableau
        if (Array.isArray(invoices) && invoices.length > 0) {
            invoices.forEach((invoice) => {
                try {
                    const parsedData = JSON.parse(invoice?.fbProductsDetails);
                    const totalTTC = parseFloat(parsedData?.cost?.totalTTC);

                    // Vérifier si totalTTC est un nombre valide
                    if (isNaN(totalTTC)) {
                        console.warn('Invalid totalTTC value:', parsedData?.cost?.totalTTC);
                        return; // Ignorer cette facture si totalTTC est invalide
                    }

                    if (invoice.fbInvoicePayementMode === '') {
                        // Facture impayée
                        unpaidCount += 1;
                        unpaidTotal += totalTTC;
                    } else {
                        // Facture payée
                        paidCount += 1;
                        paidTotal += totalTTC;
                    }
                } catch (error) {
                    console.error('Error parsing fbProductsDetails:', error);
                }
            });
        }

        return {
            unpaidCount,
            paidCount,
            unpaidTotal: unpaidTotal.toFixed(2),
            paidTotal: paidTotal.toFixed(2),
        };
    };
    const { unpaidCount, paidCount, unpaidTotal, paidTotal } = calculateInvoiceStats();

    const dashboardData = [
        {
            icon: <DescriptionIcon style={{ fontSize: 40, color: '#d32f2f' }} />,
            title: `${unpaidCount} - Impayées`,
            subtitle: 'unpaid',
            value: `${unpaidTotal} €`,
            footerStyle: { color: '#d32f2f' },
            footer: 'Factures impayées',
        },
        {
            icon: <DescriptionIcon style={{ fontSize: 40, color: 'rgb(76, 175, 80)' }} />,
            title: `${paidCount} - Payées`,
            subtitle: 'paid',
            value: `${paidTotal} €`,
            footerStyle: { color: 'rgb(76, 175, 80)' },
            footer: 'Factures payées',
        },
        {
            icon: <DescriptionIcon style={{ fontSize: 40, color: '#1976d2' }} />,
            title: `${unpaidCount + paidCount} - Total`,
            subtitle: 'all',
            value: `${(parseFloat(unpaidTotal) + parseFloat(paidTotal)).toFixed(2)} €`,
            footer: 'Total de factures',
        },
    ];

    const handleFooterClick = (data) => {
        let filteredInvoices;
        if (data.subtitle === 'unpaid') {
            filteredInvoices = invoices.filter(invoice => invoice.fbInvoicePayementMode === '');
        } else if (data.subtitle === 'paid') {
            filteredInvoices = invoices.filter(invoice => invoice.fbInvoicePayementMode !== '');
        } else {
            filteredInvoices = invoices;
        }

        const groupedByCompany = filteredInvoices.reduce((acc, invoice) => {
            const companyName = invoice.fbCompanyName;

            // On essaie de parser le total TTC
            const parsedData = JSON.parse(invoice?.fbProductsDetails);
            const totalTTC = parseFloat(parsedData?.cost?.totalTTC);

            if (!acc[companyName]) {
                acc[companyName] = {
                    owner: invoice.owner,
                    companyName,
                    invoiceCount: 0,
                    totalTTC: 0,
                };
            }

            acc[companyName].invoiceCount += 1;
            acc[companyName].totalTTC += totalTTC;

            return acc;
        }, {});

        const result = Object.values(groupedByCompany);
        setPopupContent({ data, invoices: result });
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const extractTypeAndColor = (title) => {
        if (!title) {
            return { type: '', color: 'black' };
        }
        const type = title.split(' - ')[1];
        let color = 'black';
        if (type === 'Impayées') color = '#d32f2f';
        if (type === 'Payées') color = 'rgb(76, 175, 80)';
        if (type === 'Total') color = '#1976d2';
        return { type, color };
    };

    return (
        <Box pt={2} pb={2}>
            <Grid container spacing={3}>
                {dashboardData.map((data, index) => (
                    <Grid item xs={12} sm={4} md={4} key={index}>
                        <Card>
                            <Box display="flex" alignItems="center" p={2}>
                                <Box mr={2}>{data.icon}</Box>
                                <CardContent>
                                    <Typography variant="subtitle2" color="textSecondary" pb={1.2}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {data.value}
                                    </Typography>
                                    <Typography
                                        pt={1.4}
                                        variant="body1"
                                        sx={{ ...data.footerStyle, fontSize: '.6rem', cursor: 'pointer' }}
                                        onClick={() => handleFooterClick(data)}
                                    >
                                        {data.footer}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
                <DialogTitle
                    sx={{
                        padding: 2,
                        textAlign: 'center',
                        fontWeight: 600,
                        color: extractTypeAndColor(popupContent.data.title).color,
                    }}
                >
                    {extractTypeAndColor(popupContent.data.title).type.toUpperCase()}
                </DialogTitle>
                <DialogContent sx={{ padding: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>Entreprise</TableCell>
                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>Nombre</TableCell>
                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>Total TTC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {popupContent.invoices && popupContent.invoices.length > 0 ? (
                                popupContent.invoices.map((companyData, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ padding: 1 }}>
                                            {companyData?.companyName}<br />
                                            <Typography sx={{ fontSize: '0.675rem', fontStyle: 'italic', fontWeight: 300 }}>
                                                {companyData?.owner}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }}>{companyData?.invoiceCount}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{companyData?.totalTTC?.toFixed(2)} €</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography>Pas de détails</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button onClick={handleClose} color="warning" variant="outlined" style={{ width: 150 }}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default SummaryInvoices;
