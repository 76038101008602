import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import MapIcon from "@mui/icons-material/Map";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SettingsIcon from "@mui/icons-material/Settings";
import InvoicesIcon from "@mui/icons-material/Receipt";
import { styled } from "@mui/system";
import { DRAWER_WIDTH, CURRENT_USER } from '../constants';

const DrawerContainer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Sidebar = ({ open, handleDrawerClose, onListItemClick, selectedItem }) => {
  const [user] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});

  return (
    <DrawerContainer
      variant="persistent"
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'ligthwhite',
          width: DRAWER_WIDTH,
        }
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
      <ListItem button="true" onClick={() => onListItemClick("Map")} selected={selectedItem === "Map"} sx={{ cursor: 'pointer', mb: 4 }}>
          <ListItemIcon sx={{ color: selectedItem === "Map" ? 'rgb(76, 175, 80)' : 'inherit' }}>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary="MAP" sx={{ color: selectedItem === "Map" ? 'rgb(76, 175, 80)' : 'inherit' }} />
        </ListItem>
        <ListItem button="true" onClick={() => onListItemClick("Dashboard")} selected={selectedItem === "Dashboard"} sx={{ cursor: 'pointer', mb: 4 }}>
          <ListItemIcon sx={{ color: selectedItem === "Dashboard" ? 'rgb(76, 175, 80)' : 'inherit' }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="PERFORMANCE" sx={{ color: selectedItem === "Dashboard" ? 'rgb(76, 175, 80)' : 'inherit' }} />
        </ListItem>
        <ListItem button="true" onClick={() => onListItemClick("Invoices")} selected={selectedItem === "Invoices"} sx={{ cursor: 'pointer', mb: 4 }}>
          <ListItemIcon sx={{ color: selectedItem === "Invoices" ? 'rgb(76, 175, 80)' : 'inherit' }}>
            <InvoicesIcon />
          </ListItemIcon>
          <ListItemText primary="C.A." sx={{ color: selectedItem === "Invoices" ? 'rgb(76, 175, 80)' : 'inherit' }} />
        </ListItem>
        {user.profile !== 'COMMERCIAL' &&
          <ListItem button="true" onClick={() => onListItemClick("TeamManagement")} selected={selectedItem === "TeamManagement"} sx={{ cursor: 'pointer', mb: 4 }}>
            <ListItemIcon sx={{ color: selectedItem === "TeamManagement" ? 'rgb(76, 175, 80)' : 'inherit' }}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="EQUIPES" sx={{ color: selectedItem === "TeamManagement" ? 'rgb(76, 175, 80)' : 'inherit' }} />
          </ListItem>
        }
        {/*
        <ListItem button="true" onClick={() => onListItemClick("Favorites")} selected={selectedItem === "Favorites"} sx={{ cursor: 'pointer', mb: 4 }}>
          <ListItemIcon sx={{ color: selectedItem === "Favorites" ? 'rgb(76, 175, 80)' : 'inherit' }}>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText primary="FAVORIS" sx={{ color: selectedItem === "Favorites" ? 'rgb(76, 175, 80)' : 'inherit' }} />
        </ListItem>
        */}
      </List>
      <Divider />
      <List>
        <ListItem button="true" onClick={() => onListItemClick("Settings")} selected={selectedItem === "Settings"} sx={{ cursor: 'pointer', mb: 4 }}>
          <ListItemIcon sx={{ color: selectedItem === "Settings" ? 'rgb(76, 175, 80)' : 'inherit' }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="PARAMETRAGES" sx={{ color: selectedItem === "Settings" ? 'rgb(76, 175, 80)' : 'inherit' }} />
        </ListItem>
      </List>
    </DrawerContainer>
  );
}

export default Sidebar;