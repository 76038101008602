import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  DialogTitle,
  Button,
  DialogActions,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  TextField
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertActions, freshbooksActions } from '../redux/actions';
import { useDispatch } from "react-redux";
import { FreshBooksService } from "../services/freshbooks/freshbbooks.service";

const InvoiceListModal = ({ open, onClose, openCreateInvoice, filteredInvoices, companyId }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setInvoices(filteredInvoices);
  }, [filteredInvoices]);

  const [openRow, setOpenRow] = useState(null);
  const handleRowToggle = (invoiceId) => {
    setOpenRow((prev) => (prev === invoiceId ? null : invoiceId)); // Toggle l'état openRow
    setSelectedInvoiceId(invoiceId);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleSubmitPayment = async () => {
    setPaymentLoading(true);

    if (!selectedInvoiceId || !selectedPaymentMethod) {
      dispatch(alertActions.error("Veuillez sélectionner une facture et un moyen de paiement."));
      setPaymentLoading(false);
      return;
    }

    // Trouver la facture sélectionnée
    const selectedInvoice = invoices?.find(invoice => invoice.id === selectedInvoiceId);

    if (!selectedInvoice) {
      dispatch(alertActions.error("Facture introuvable."));
      setPaymentLoading(false);
      return;
    }

    let totalTTC = 'N/A';
    try {
      const details = JSON.parse(selectedInvoice.fbProductsDetails);
      totalTTC = details?.cost?.totalTTC || 'N/A';
    } catch (e) {
      console.error('Erreur lors du parsing de fbProductsDetails:', e);
      dispatch(alertActions.error("Erreur lors de la récupération du montant total."));
      setPaymentLoading(false);
      return;
    }

    // Créer le paiement et récupérer la liste des factures mises à jour
    try {
      await FreshBooksService.createPayment(selectedPaymentMethod, selectedInvoiceId, totalTTC);
      dispatch(alertActions.success("Paiement effectué avec succès."));

      // Récupérer la liste des factures mises à jour
      const updatedInvoices = await FreshBooksService.getInvoiceListByCompanyId(companyId);
      setInvoices(updatedInvoices);
    } catch (error) {
      console.error("Erreur lors du paiement ou du rafraîchissement des factures:", error);
      dispatch(alertActions.error("Une erreur s'est produite lors du paiement ou du rafraîchissement des factures."));
    } finally {
      setPaymentLoading(false); // Arrêter le chargement une fois l'opération terminée
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 0,
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <DialogTitle align="center">
          <Box
            sx={{
              paddingBottom: 2,
              borderRadius: "4px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Factures
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <Box sx={{
          pl: 2, pr: 2,
          maxHeight: '60vh',
          overflowY: 'auto',
        }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ padding: "4px", textAlign: "left", pr: 3, fontWeight: 600 }} >Numéro</TableCell>
                <TableCell sx={{ padding: "4px", textAlign: "right", pr: 4, fontWeight: 600 }} >Montant&nbsp;(€)</TableCell>
                <TableCell sx={{ padding: "4px", textAlign: "left", pr: 3, fontWeight: 600 }} >Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(invoices) && invoices.length > 0 ? (
                invoices.map((invoice) => {
                  const status = invoice.fbInvoicePayementMode || 'Non Payé';
                  return (
                    <React.Fragment key={invoice.id}>
                      {/* Ligne principale */}
                      <TableRow
                        onClick={() => handleRowToggle(invoice.id)}
                        style={{ cursor: "pointer", height: "36px", backgroundColor: status === 'Non Payé' ? 'rgb(255 103 103)' : 'rgb(76, 175, 80)' }}
                      >
                        <TableCell sx={{ padding: "4px" }}>
                          <IconButton size="small">
                            {openRow === invoice.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell style={{ padding: "4px", textAlign: "left" }}>{invoice.fbInvoiceNumber}</TableCell>
                        <TableCell sx={{ padding: "4px", paddingRight: 4, textAlign: 'right' }}>
                          {invoice.fbProductsDetails ? (
                            (() => {
                              try {
                                const details = JSON.parse(invoice.fbProductsDetails);
                                return details?.cost?.totalTTC ? `${details.cost.totalTTC} €` : 'N/A';
                              } catch (e) {
                                console.error('Erreur lors du parsing de fbProductsDetails:', e);
                                return 'N/A';
                              }
                            })()
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell sx={{ padding: "4px" }}>
                          {status === 'Non Payé' ? (
                            "Non Payé"
                          ) : (
                            "Payé"
                          )}
                        </TableCell>
                      </TableRow>

                      {/* Contenu du collapse */}
                      <TableRow>
  <TableCell colSpan={4} style={{ padding: 0 }}>
    <Collapse in={openRow === invoice.id} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 0 }}>
        <Card variant="outlined">
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 0, padding: 1 }}>
            <Grid container direction="row" justifyContent="space-between">
              {/* Colonne de gauche : Facture N° et autres informations */}
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  <strong>Facture N° : </strong> {invoice.fbInvoiceNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Facturé à : </strong> {invoice.fbBilledTo}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Facturé par : </strong> {invoice.fbHubspotOwner}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Emission : </strong> {new Date(invoice.fbDateOfIssue).toLocaleDateString('fr-FR')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Echéance : </strong> {new Date(invoice.fbDueDate).toLocaleDateString('fr-FR')}
                </Typography>
              </Grid>

              {/* Colonne de droite : Statut et Totaux */}
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                {/* Statut à droite */}
                <Typography variant="body2" color="textSecondary">
                  <strong style={{ color: status === 'Non Payé' ? '#d32f2f' : 'green', fontWeight: 'bold' }}>
                    {status === 'Non Payé' ? 'Non Payé' : 'Payé'}
                  </strong>
                </Typography>

                {/* Totaux à droite */}
                <Typography variant="body2" color="textSecondary">
                  <strong>Total HT : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalHT : 'N/A'} €
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>TVA : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.tax : 'N/A'} €
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Total TTC : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalTTC : 'N/A'} €
                </Typography>
              </Grid>
            </Grid>

            {/* Voir la facture */}
            <Box sx={{ pt: 1, pb: 1, height: "30px" }}>
              <a
                href={invoice.fbInvoiceLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", width: "100%" }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  fullWidth
                  sx={{ width: "100%" }}
                >
                  Voir la facture
                </Button>
              </a>
            </Box>

            {/* Section à droite : Statut de la facture et formulaire de paiement */}
            <Box>
              {status === 'Non Payé' && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 3 }}>
                  <TextField
                    select
                    value={selectedPaymentMethod}
                    onChange={handlePaymentMethodChange}
                    size="small"
                    sx={{
                      flexGrow: 1,
                      marginRight: 1,
                    }}
                    label="Méthode de paiement"
                  >
                    <MenuItem value="Check">Paiement par chèque</MenuItem>
                    <MenuItem value="Cash">Paiement en espèces</MenuItem>
                  </TextField>

                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleSubmitPayment}
                    size="small"
                    sx={{
                      height: "38px",
                    }}
                  >
                    {!paymentLoading ? 'Enregistrer' : <CircularProgress size={13} color="inherit" />}
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Collapse>
  </TableCell>
</TableRow>




                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '16px', fontWeight: 'bold' }}>
                    Aucune facture disponible.
                  </TableCell>
                </TableRow>
              )}

            </TableBody>

          </Table>
        </Box>
        <DialogActions
          sx={{
            paddingY: 1,
            marginY: 2,
            justifyContent: 'center',
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => openCreateInvoice()}
            sx={{
              width: 180,
              fontSize: "0.675rem",
            }}
          >
            Créer une facture
          </Button>
          <Button
            variant="outlined"
            color="success"
            fullWidth
            disabled
            sx={{
              width: 180,
              fontSize: "0.675rem",
            }}
          >
            Créer un abonnement
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default InvoiceListModal;
