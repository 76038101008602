import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Pour le nombre de visites
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Pour les accords obtenus
import BuildIcon from '@mui/icons-material/Build';
import EuroIcon from '@mui/icons-material/Euro';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RedeemIcon from '@mui/icons-material/Redeem';

const Summary = ({ global }) => {

    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState({ title: '', companies: [] });
    const dashboardData = [
        {
            icon: <VisibilityIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Visites',
            value: global?.visites?.companies.length,
            footer: 'Liste de visites',
            companies: global?.visites?.companies,
            footerStyle: { color: '#d32f2f' },
        },
        {
            icon: <CheckCircleIcon style={{ fontSize: 40, color: '#2196F3' }} />,
            title: 'Obtenus',
            value: global?.accords?.companies.length,
            companies: global?.accords?.companies,
            footer: 'Liste d\'accords',
        },
        {
            icon: <VerifiedUserIcon style={{ fontSize: 40, color: '#4CAF50' }} />,
            title: 'Validés',
            value: global?.accords_valide?.companies.length,
            companies: global?.accords_valide?.companies,
            footer: 'Liste d\'accords',
        },
        {
            icon: <BuildIcon style={{ fontSize: 40, color: '#F44336' }} />,
            title: 'Installations',
            value: global?.installation?.companies.length,
            companies: global?.installation?.companies,
            footer: 'Liste d\'installations',
        },
        {
            icon: <RedeemIcon style={{ fontSize: 40, color: '#00ACC1' }} />,
            title: 'Primes',
            value: global.total_prime,
            companies: [
                ...(Array.isArray(global?.accords?.companies) ? global.accords.companies : []),
                ...(Array.isArray(global?.accords_valide?.companies) ? global.accords_valide.companies : []),
                ...(Array.isArray(global?.installation?.companies) ? global.installation.companies : []),
            ],
            footer: 'Détails de primes',
        },
        {
            icon: <EuroIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Chiffre d\'affaires',
            value: global?.chiffre_d_affaires,
            footer: 'Détails de chiffre d\'affaires',
        },
    ];

    const handleFooterClick = (title, companies) => {
        if (title === 'Détails de primes') {
            const primesByProspecteur = calculatePrimesByProspecteur(companies);
            setPopupContent({ title, companies: primesByProspecteur });
        }
        else if (title === 'Détails de chiffre d\'affaires') {
            //ne fait rien
            return
        }
        else {
            setPopupContent({ title, companies });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const calculatePrimesByProspecteur = (companies) => {
        const primesByProspecteur = companies.reduce((acc, company) => {
            const { owner, prime } = company;
            acc[owner] = (acc[owner] || 0) + 10;
            return acc;
        }, {});
        return Object.entries(primesByProspecteur).map(([prospecteur, totalPrime]) => ({
            prospecteur,
            totalPrime,
        }));
    };


    return (
        <Box pt={2} pb={2}>
            <Grid container spacing={3}>
                {dashboardData.map((data, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                        <Card>
                            <Box display="flex" alignItems="center" p={2}>
                                <Box mr={2}>{data.icon}</Box>
                                <CardContent>
                                    <Typography variant="subtitle2" color="textSecondary" pb={1.2}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {data.value}
                                    </Typography>
                                    <Typography
                                        pt={1.4}
                                        variant="body1"
                                        sx={{ ...data.footerStyle, fontSize: '.6rem', cursor: 'pointer' }}
                                        onClick={() => handleFooterClick(data.footer, data.companies)}
                                    >
                                        {data.footer}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
                <DialogTitle color='warning' sx={{ padding: 2, textAlign: 'center', fontWeight: 600 }}>{popupContent.title}</DialogTitle>
                <DialogContent sx={{ padding: 2 }}>
                    <Table>
                        {popupContent.title === 'Détails de primes' ? (
                            <>
                                <TableHead>
                                    {popupContent.companies.length > 0 &&
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>PROSPECTEUR</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1, textAlign: 'center' }}>TOTAL PRIMES</TableCell>
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {popupContent.companies.length > 0 ? (
                                        popupContent.companies.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ padding: 1 }}>{item.prospecteur}</TableCell>
                                                <TableCell sx={{ padding: 1, textAlign: 'center' }}>{item.totalPrime} &euro;</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>Pas de détails</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        ) : (
                            <>

                                <TableHead>
                                    {popupContent.companies.length > 0 &&
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>ENTREPRISE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                {popupContent?.title === 'Liste d\'installations' ? 'INSTALLATEUR' : 'PROSPECTEUR'}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {popupContent.companies.length > 0 ? (
                                        popupContent.companies.map((company, index) => (
                                            <TableRow key={company.id}>
                                                <TableCell sx={{ padding: 1 }}>{company.name}</TableCell>
                                                <TableCell sx={{ padding: 1 }}>{company.owner}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>Pas de détails</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        )}
                    </Table>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button onClick={handleClose} color="warning" variant="outlined" style={{ width: 150 }}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Summary;
