import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { loading } from './loader.reducer';
import { authentication } from './auth.reducer';
import { hubspot } from './hubspot.reducer';
import { freshbooks } from './freshbooks.reducer';

const RootReducer = combineReducers({
    alert,
    authentication,
    loading,
    hubspot,
    freshbooks
});

export default RootReducer;
