import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, useTheme, useMediaQuery, styled } from "@mui/material";
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import Content from '../layouts/Content';
import { jwt } from '../helpers/jwt';
import { CURRENT_USER, DRAWER_WIDTH } from '../constants';

const Root = styled("div")({
    display: "flex",
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            marginLeft: open ? DRAWER_WIDTH : 0,
        },
        marginLeft: 0,
    })
);

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const HomePage = ({ darkMode, toggleDarkMode }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Détecte si l'écran est mobile
    const { alert, loading } = useSelector(state => state);
    const isTokenExpired = jwt.isExpired();

    const [open, setOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState("Map");
    const navigate = useNavigate();
    const [currentUser] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});

    const handleNavigateToMap = () => {
        navigate('/map');
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleListItemClick = (item) => {
        setSelectedItem(item);
        if (isMobile) {
            handleDrawerClose();
        }
    };

    return (
        <Root>
            <CssBaseline />
            <Header
                handleDrawerToggle={handleDrawerToggle}
                toggleDarkMode={toggleDarkMode}
                darkMode={darkMode}
            />
            <Sidebar
                handleDrawerClose={handleDrawerClose}
                open={open}
                onListItemClick={handleListItemClick}
                selectedItem={selectedItem}
                currentUser={currentUser}
            />
            <Main open={open}>
                <DrawerHeader />
                <Content selectedItem={selectedItem} darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </Main>
        </Root>
    );
}

export default HomePage;