import React, { } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './Login';
import RegisterPage from './Register';
import PasswordPage from './Password';
import HomePage from './Home';
import Map from './MapComponent';
import { jwt } from '../helpers/jwt';
import ResetpasswordPage from './ResetPassword';

const MainRoutes = ({ darkMode, toggleDarkMode }) => {
    const location = useLocation();
    const isTokenExpired = jwt.isExpired();

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/init-pwd" element={<PasswordPage />} />
            <Route path="/reset-pwd" element={<ResetpasswordPage />} />
            <Route
                path="/home"
                // element={
                //     !isTokenExpired || location.pathname === '/login'
                //         ? <HomePage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                //         : <Navigate to="/login" />
                // }
                element={
                    isTokenExpired
                        ? (
                            <Navigate
                                to={`/login?q=${new URLSearchParams(location.search).get('q')}`}
                                replace
                            />
                        )
                        : <HomePage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                }
            />
            <Route
                path="/"
                element={
                    !isTokenExpired
                        ? <Navigate to="/home" />
                        : <Navigate to="/login" />
                }
            />
            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    );
};

export default MainRoutes;