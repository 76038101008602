import { commonService } from '../generic.service';

const updateLifecyclestage = (id, lifecyclestage, lastLifecyclestage) => {
  return commonService.patch(`hubspot/companies/${id}/update`, { lifecyclestage, lastLifecyclestage });

};

const updateOwner = (id, hubspot_owner_id) => {
  return commonService.patch(`hubspot/companies/${id}/update`, { hubspot_owner_id: hubspot_owner_id });
};

const createCompany = (data) => {
  return commonService.post(`hubspot/companies`, { data: data });
};

const updateNotes = (id, notes_sur_l_inscription) => {
  return commonService.patch(`hubspot/companies/${id}/update`, { notes_sur_l_inscription: notes_sur_l_inscription });
};

const updateNombreVisite = (id, nombre_de_visite, lastLifecyclestage, date_des_visites) => {
  return commonService.patch(`hubspot/companies/${id}/update`, { nombre_de_visite, lastLifecyclestage, date_des_visites });
};

const updateLastVisiteTimestamp = (id, lastvisit) => {
  return commonService.patch(`hubspot/companies/${id}/update`, { lastvisit: lastvisit });
};

const get = (owners) => {
  return commonService.get(owners);
};

const updateConversionDate = (id, columnName, date) => {
  return commonService.patch(`hubspot/companies/${id}/update-date`, { [columnName]: date });
};

const getToken = () => {
  return commonService.get(`hubspot/encrypt`);
};

const getHubSpotCarteInformation = (id) => {
  return commonService.get(`hubspot/${id}/carteInforamation`);
}

const associateCompaniesWithContact = (contactId, companiesIds) => {
  if (!contactId || !Array.isArray(companiesIds) || companiesIds.length === 0) {
    return Promise.reject(new Error('Les données sont invalides : contactId ou CompaniesIds manquants.'));
  }

  const data = {
    contactId: contactId,
    companiesIds: companiesIds
  };

  return commonService.patch('hubspot/associateCompaniesContact', { data })
    .then(response => {
      console.log('Association réussie :', response);
      return response; 
    })
    .catch(error => {
      console.error('Erreur lors de l\'association des entreprises au contact :', error);
      throw error; 
    });
}

export const HubspotService = {
  updateLifecyclestage,
  createCompany,
  updateNotes,
  updateOwner,
  updateNombreVisite,
  updateLastVisiteTimestamp,
  get,
  updateConversionDate,
  getToken,
  getHubSpotCarteInformation,
  associateCompaniesWithContact
};
