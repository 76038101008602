import Dexie from 'dexie';
import packageJson from '../../package.json';
const { REACT_APP_APIURL } = process.env;

export const db = new Dexie(`${packageJson.name}_${packageJson.version}`);
db.version(1).stores({
    // Clé primaire et propriétés indexées
    companies: 'id, name, hubspot_owner_id, lifecyclestate, firstseenafterclient, secondseenafterclient, hs_lastmodifieddate, notes_sur_l_inscription, thirdseenafterclient, hubspot_owner_name, date_des_visites,date_de_conversion_en_accord, date_de_suppression_en_local, [hs_lastmodifieddate+date_de_suppression_en_local]'
});

// Remplacé : PB de récupération de denière modification
// async function getLatestModifiedDate() {
//     // const latestRecord = await db.companies.orderBy('hs_lastmodifieddate').last();
//     // return latestRecord ? latestRecord.hs_lastmodifieddate : null;
//     const latestRecord = await db.companies
//         .orderBy('[hs_lastmodifieddate+date_de_suppression_en_local]')
//         .last();

//     if (!latestRecord) return null;

//     const { hs_lastmodifieddate, date_de_suppression_en_local } = latestRecord;

//     // Retourner la date la plus ancienne
//     if (hs_lastmodifieddate && date_de_suppression_en_local) {
//         return hs_lastmodifieddate < date_de_suppression_en_local
//             ? hs_lastmodifieddate
//             : date_de_suppression_en_local;
//     }

//     // Retourner celle qui est définie si une seule est disponible
//     return hs_lastmodifieddate || date_de_suppression_en_local;
// }

async function getLatestModifiedDate() {
    const latestRecord = await db.companies
        .orderBy('hs_lastmodifieddate')  // Trier par hs_lastmodifieddate
        .last();  // Récupérer le dernier enregistrement (plus récent)

    if (!latestRecord) return null;

    const { hs_lastmodifieddate, date_de_suppression_en_local } = latestRecord;

    // Si date_de_suppression_en_local est définie et est plus ancienne, alors la retourner
    if (date_de_suppression_en_local && hs_lastmodifieddate) {
        return hs_lastmodifieddate < date_de_suppression_en_local
            ? hs_lastmodifieddate
            : date_de_suppression_en_local;
    }

    // Retourner la plus récente des deux dates (si une des deux est null, on retourne l'autre)
    return hs_lastmodifieddate || date_de_suppression_en_local;
}

export async function fetchAndStoreCompanies() {
    try {
        //console.log('Mise à jour indexDb [START]');
        const startDate = await getLatestModifiedDate();
        console.log("StartDate - IDB : ", startDate);
        const url = new URL(`${REACT_APP_APIURL}/Companies`);
        if (startDate) {
            url.searchParams.append('startDate', startDate);
        }

        const response = await fetch(url.toString());
        const companies = await response.json();
        //console.log("Companies to insert count : ", companies.length);
        await db.companies.bulkPut(companies);

        //console.log('Mise à jour indexDb [END]');
    } catch (error) {
        console.error('Erreur lors de la récupération ou l\'insertion des données', error);
    }
}
