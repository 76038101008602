import React from 'react';
import { Box, Card, CardContent, Typography, CircularProgress, TablePagination, TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Grid, Button, MenuItem, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import { alertActions } from '../../../redux/actions';
import { FreshBooksService } from '../../../services/freshbooks/freshbbooks.service';
import { InvoiceStatisticService } from '../../../services/statistics/invoiceStatistic.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import DescriptionIcon from '@mui/icons-material/Description';
import { fetchCompanyById } from '../../../services/companies/poi.service';
import { ContactService } from '../../../services/companies/contact.service';
import { jwtDecode } from 'jwt-decode';

const ListInvoices = ({
    invoicesList,
    invoiceStatus,
    teamId,
    member,
    paymentMethod,
    startDate,
    endDate
}) => {
    const dispatch = useDispatch();
    const [currentUser] = useState(jwtDecode(localStorage.getItem('TOKEN_USER')) || {});
    const [invoices, setInvoices] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortDirection, setSortDirection] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [openRow, setOpenRow] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [openContactListDialog, setOpenContactListDialog] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");


    const paymentMethodMapping = {
        Check: "Chèque",
        Cash: "Espèces",
        "Bank Transfer": "Virement bancaire",
        Card: "Carte bancaire",
    };

    useEffect(() => {
        setInvoices(invoicesList);
        console.log("invcoooice  invoicesList ", invoicesList);
    }, [invoicesList]);

    const handleSort = (column) => {
        const isAsc = sortColumn === column && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortColumn(column);
    };

    const filteredInvoices = invoices.filter((invoice) => {
        const searchLower = searchQuery.toLowerCase();
        const matchesSearch =
            invoice?.fbInvoiceNumber?.includes(searchLower) ||
            invoice?.owner?.toLowerCase().includes(searchLower) ||
            invoice?.fbCompanyName?.toLowerCase().includes(searchLower) ||
            invoice?.date?.toLowerCase().includes(searchLower);
        const matchesStatus = () => {
            if (invoiceStatus === "paid") {
                return invoice.fbInvoicePayementMode !== "";
            } else if (invoiceStatus === "unpaid") {
                return invoice.fbInvoicePayementMode === "";
            }
            return true;
        };

        return matchesSearch && matchesStatus();
    });

    const extractTotalTTC = (invoice) => {
        if (invoice?.fbProductsDetails) {
            try {
                const parsedData = JSON.parse(invoice.fbProductsDetails);
                return parsedData?.cost?.totalTTC && !isNaN(parseFloat(parsedData.cost.totalTTC)) ? parseFloat(parsedData.cost.totalTTC) : 0;
            } catch (error) {
                console.error("Erreur de parsing JSON :", error);
                return 0;
            }
        }
        return 0;
    };

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const handleSubmitPayment = async () => {
        setPaymentLoading(true);

        if (!selectedInvoiceId || !selectedPaymentMethod) {
            dispatch(alertActions.error("Veuillez sélectionner une facture et un moyen de paiement."));
            setPaymentLoading(false);
            return;
        }

        // Trouver la facture sélectionnée
        const selectedInvoice = invoices?.find(invoice => invoice.id === selectedInvoiceId);

        if (!selectedInvoice) {
            dispatch(alertActions.error("Facture introuvable."));
            setPaymentLoading(false);
            return;
        }

        let totalTTC = 'N/A';
        try {
            const details = JSON.parse(selectedInvoice.fbProductsDetails);
            totalTTC = details?.cost?.totalTTC || 'N/A';
        } catch (e) {
            console.error('Erreur lors du parsing de fbProductsDetails:', e);
            dispatch(alertActions.error("Erreur lors de la récupération du montant total."));
            setPaymentLoading(false);
            return;
        }
        try {
            await FreshBooksService.createPayment(selectedPaymentMethod, selectedInvoiceId, totalTTC);
            dispatch(alertActions.success("Paiement effectué avec succès."));
            const allResult = InvoiceStatisticService.getInvoiceGlobalStatForDateRange(teamId, member, paymentMethod, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
            setInvoices((await allResult).invoiceList);
            setOpenRow(false);
        } catch (error) {
            dispatch(alertActions.error("Une erreur s'est produite lors du paiement ou du rafraîchissement des factures."));
        } finally {
            setPaymentLoading(false); // Arrêter le chargement une fois l'opération terminée
        }
    };

    const sortedInvoices = [...filteredInvoices].sort((a, b) => {
        const valueA =
            sortColumn === "fbProductsDetails" ? extractTotalTTC(a) : a[sortColumn];
        const valueB =
            sortColumn === "fbProductsDetails" ? extractTotalTTC(b) : b[sortColumn];

        if (valueA == null && valueB == null) return 0;
        if (valueA == null) return sortDirection === "asc" ? -1 : 1;
        if (valueB == null) return sortDirection === "asc" ? 1 : -1;

        if (typeof valueA === "number" && typeof valueB === "number") {
            return (valueA - valueB) * (sortDirection === "asc" ? 1 : -1);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB) * (sortDirection === "asc" ? 1 : -1);
        }

        return 0;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowToggle = (invoiceId) => {
        setOpenRow((prev) => (prev === invoiceId ? null : invoiceId)); // Toggle l'état openRow
        setSelectedInvoiceId(invoiceId);
    };

    const handleHubSpotClick = async (companyId) => {
        const result = await fetchCompanyById(companyId);
        const hubSpotUrl = result.lien_fiche_hubspot
        window.open(hubSpotUrl, "_blank");
    };

    const handleShowContactList = (companyId, email, phone) => {
        setContactEmail(email);
        setContactPhone(phone);
        setOpenContactListDialog(true);
        fetchContacts(companyId);
    };
    const handleCloseContactListDialog = () => {
        setOpenContactListDialog(false);
    };

    const handleGoToCompanyMap = async (companyId) => {
        const baseUrl = window.location.origin;
        const urldebaseUrl = `${baseUrl}/home?q=${companyId}`;
        window.open(urldebaseUrl, "_blank");
    };

    const fetchContacts = async (companyId) => {
        try {
            const contactsFromCompanyId = await ContactService.getContactsList(companyId);
            setContacts(contactsFromCompanyId);
        } catch (error) {
        }
    };

    return (
        <Box pt={2} pb={2}>
            <Card>
                <CardContent>
                    <TextField
                        label="Recherche (facture, entreprise, commercial...)"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        sx={{
                            mb: 1,
                            maxWidth: { xs: "100%", sm: "450px" },
                            "& .MuiInputBase-root": {
                                height: "40px",
                            },
                            "& .MuiInputLabel-root": {
                                lineHeight: "40px",
                                transform: "translate(14px, 5px) scale(0.75)",
                            },
                            "& .MuiOutlinedInput-root": {
                                padding: "0 5px",
                                borderColor: "#ffffff",
                                "&:hover": {
                                    borderColor: "#999",
                                },
                                "&.Mui-focused": {
                                    borderColor: "#666",
                                },
                            },
                            "& .MuiInputLabel-shrink": {
                                transform: "translate(14px, -16px) scale(0.75)",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {filteredInvoices.length > 0 ? (
                        <>
                            <TableContainer component={Paper} sx={{ overflowX: "auto", maxHeight: "600px" }}>
                                <Table sx={{ minWidth: 650 }} aria-label="invoice table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            {/* Ajoute une cellule pour le collapse */}
                                            <TableCell onClick={() => handleSort("fbInvoiceNumber")}>
                                                N° Facture
                                                {sortColumn === "fbInvoiceNumber" && sortDirection && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('fbProductsDetails')}>
                                                Montant TTC
                                                {sortColumn === 'fbProductsDetails' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('fbCompanyName')}>
                                                Entreprise
                                                {sortColumn === 'fbCompanyName' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('owner')}>
                                                Commercial
                                                {sortColumn === 'owner' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('team')}>
                                                Equipe
                                                {sortColumn === 'team' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('date')}>
                                                Échéance
                                                {sortColumn === 'date' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('fbInvoicePayementMode')}>
                                                Statut
                                                {sortColumn === 'fbInvoicePayementMode' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('fbInvoicePayementMode')}>
                                                Mode de paiement
                                                {sortColumn === 'fbInvoicePayementMode' && (
                                                    <Button style={{ padding: 0, minWidth: 'auto', fontSize: '12px' }}>
                                                        {sortDirection === 'asc' ? '▲' : '▼'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedInvoices
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((invoice, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        sx={{
                                                            backgroundColor:
                                                                invoice.fbInvoicePayementMode === ""
                                                                    ? "rgb(255 103 103)"
                                                                    : "rgb(76, 175, 80)",
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => handleRowToggle(invoice.id)}
                                                            >
                                                                {openRow === invoice.id ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{invoice?.fbInvoiceNumber}</TableCell>
                                                        <TableCell>
                                                            {(() => {
                                                                try {
                                                                    const parsedData = JSON.parse(invoice.fbProductsDetails);
                                                                    return parsedData?.cost?.totalTTC + " €";
                                                                } catch (error) {
                                                                    console.error("Erreur de parsing JSON :", error);
                                                                    return "Erreur de données";
                                                                }
                                                            })()}
                                                        </TableCell>
                                                        <TableCell>{invoice?.fbCompanyName}</TableCell>
                                                        <TableCell>{invoice?.owner}</TableCell>
                                                        <TableCell>{invoice?.team}</TableCell>
                                                        <TableCell>
                                                            {new Date(invoice.date).toLocaleDateString("fr-FR")}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                variant="body1"
                                                                color="black"
                                                                sx={{
                                                                    display: "inline-block",
                                                                    padding: "3px 0",
                                                                    textAlign: "center",
                                                                    border: "1px solid",
                                                                    borderRadius: 4,
                                                                    width: "80px",
                                                                    borderColor: "black"
                                                                }}
                                                            >
                                                                {invoice.fbInvoicePayementMode !== "" ? "Payée" : "Impayée"}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>{paymentMethodMapping[invoice?.fbInvoicePayementMode] || ""}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={12} style={{ padding: 0, borderBottom: "none" }}>
                                                            <Collapse in={openRow === invoice.id} timeout="auto" unmountOnExit>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "left",  // Centrer horizontalement
                                                                        maxWidth: "calc(100vw - 64px)",
                                                                    }}
                                                                >
                                                                    <Card
                                                                        variant="outlined"
                                                                        sx={{
                                                                            borderRadius: 0,
                                                                            boxShadow: 0,
                                                                            width: "100%", marginRight: "auto", // Centrer horizontalement
                                                                        }}
                                                                    >
                                                                        <CardContent>
                                                                            <Typography
                                                                                variant="h6"
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center",
                                                                                    marginBottom: 2,
                                                                                    fontSize: "1rem",
                                                                                    fontWeight: 600,
                                                                                    color: invoice.fbInvoicePayementMode === "" ? "#d32f2f" : "green",
                                                                                }}
                                                                            >
                                                                                <span>Facture N° : {invoice.fbInvoiceNumber}</span>
                                                                                <span>{invoice.fbInvoicePayementMode === "" ? "Non Payé" : "Payé"}</span>
                                                                            </Typography>

                                                                            <Grid container spacing={2}>
                                                                                {/* Colonne de gauche */}
                                                                                <Grid container item xs={6} spacing={2}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <strong>Facture N° :</strong> {invoice.fbInvoiceNumber}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <strong>Facturé à :</strong> {invoice.fbBilledTo}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <strong>Facturé par :</strong> {invoice.fbHubspotOwner}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <strong>Émission :</strong> {new Date(invoice.fbDateOfIssue).toLocaleDateString("fr-FR")}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <strong>Échéance :</strong> {new Date(invoice.fbDueDate).toLocaleDateString("fr-FR")}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {/* Colonne de droite */}
                                                                                <Grid container item xs={6} justifyContent="flex-end" spacing={2}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary" sx={{ textAlign: "right" }}>
                                                                                            <strong>Payé :</strong> {invoice.fbInvoicePayementMode === "" ? "Non Payé" : "Payé"}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary" sx={{ textAlign: "right" }}>
                                                                                            <strong>Total HT :</strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalHT : "N/A"} €
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary" sx={{ textAlign: "right" }}>
                                                                                            <strong>TVA :</strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.tax : "N/A"} €
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography variant="body2" color="textSecondary" sx={{ textAlign: "right" }}>
                                                                                            <strong>Total TTC :</strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalTTC : "N/A"} €
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Box sx={{ textAlign: "-webkit-center", width: "100%" }}>
                                                                                <Box
                                                                                    className="button-container"
                                                                                    sx={{ pt: 3, textAlign: "center", maxWidth: 450 }}
                                                                                >
                                                                                    <a
                                                                                        className="icon-button"
                                                                                        href={invoice.fbInvoiceLink}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        style={{ textDecoration: "none", color: "#2e7d32" }}
                                                                                        title="Consulter la facture"
                                                                                    >
                                                                                        <DescriptionIcon style={{ fontSize: 25, color: "rgb(76, 175, 80)" }} />
                                                                                    </a>
                                                                                    <div
                                                                                        className="icon-button"
                                                                                        onClick={() => handleHubSpotClick(invoice.fbHubspotCompanyId)}
                                                                                        title="Ouvrir la fiche HubSpot"
                                                                                    >
                                                                                        <img
                                                                                            src="/assets/images/Hubspot-icon-logo.png"
                                                                                            alt="HubSpot Logo"
                                                                                            style={{ width: "20px", height: "20px" }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className="icon-button"
                                                                                        onClick={() =>
                                                                                            handleShowContactList(invoice?.fbHubspotCompanyId, invoice?.fbContactEmail, invoice?.fbContactPhone)
                                                                                        }
                                                                                        title="Afficher les contacts"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faUsers} size="lg" />
                                                                                    </div>
                                                                                    <div
                                                                                        className="icon-button"
                                                                                        onClick={() => handleGoToCompanyMap(invoice?.fbHubspotCompanyId)}
                                                                                        title="Voir l'entreprise"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faBuilding} size="lg" />
                                                                                    </div>
                                                                                </Box>
                                                                                {invoice.fbInvoicePayementMode === "" && (
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center", // Centrer verticalement
                                                                                            justifyContent: "space-between",
                                                                                            maxWidth: 450,
                                                                                            pt: 3,
                                                                                        }}
                                                                                    >
                                                                                        <TextField
                                                                                            select
                                                                                            value={selectedPaymentMethod}
                                                                                            onChange={handlePaymentMethodChange}
                                                                                            size="small"
                                                                                            label="Méthode de paiement"
                                                                                            sx={{ flexGrow: 1, marginRight: 2 }}
                                                                                        >
                                                                                            <MenuItem value="Check">Paiement par chèque</MenuItem>
                                                                                            <MenuItem value="Cash">Paiement en espèces</MenuItem>
                                                                                            {currentUser.profile === "SUPERADMIN" && (
                                                                                                <MenuItem value="bank_transfer">Virement bancaire</MenuItem>
                                                                                            )}
                                                                                        </TextField>
                                                                                        <Button
                                                                                            variant="outlined"
                                                                                            color="success"
                                                                                            onClick={handleSubmitPayment}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center", // Alignement vertical du texte et du CircularProgress
                                                                                                justifyContent: "center", // Centrer le contenu
                                                                                                minWidth: "120px", // Largeur minimale pour que le bouton soit assez large
                                                                                                height: "40px", // Taille du bouton
                                                                                                padding: "0 16px", // Espacement horizontal pour ajuster le contenu
                                                                                            }}
                                                                                        >
                                                                                            {!paymentLoading ? "Enregistrer" : <CircularProgress size={20} color="inherit" />}
                                                                                        </Button>
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>



                                                </React.Fragment>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                component="div"
                                count={sortedInvoices.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Lignes par page"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                            />
                        </>
                    ) : (
                        <Typography variant="h6" sx={{ textAlign: "center", my: 0.01 }}>
                            Aucune facture
                        </Typography>
                    )}
                </CardContent>
            </Card>
            <Dialog
                open={openContactListDialog}
                onClose={handleCloseContactListDialog}
                PaperProps={{ style: { borderRadius: 5 } }}
                maxWidth="md"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        width: '600px',
                        height: '300px',
                        maxWidth: '100%',
                    },
                }}
            >
                <DialogTitle style={{ textAlign: 'center' }}>
                    Liste des Contacts
                    <IconButton
                        style={{ position: 'absolute', right: 8, top: 8 }}
                        onClick={handleCloseContactListDialog}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Prénom</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Téléphone</TableCell>
                                    <TableCell>E-mail</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contacts.map((contact, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{contact.properties.firstname}</TableCell>
                                        <TableCell>{contact.properties.lastname}</TableCell>
                                        <TableCell>{contactPhone}</TableCell>
                                        <TableCell>{contactEmail}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </Box>

    );
};

export default ListInvoices;
