import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColorForPhase, columnMapping, TIME_LIMIT_NAVIGATE_TO_THE_CLOUD } from '../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faUserPlus, faCheckCircle, faSave, faEdit, faPencilAlt, faTrashAlt, faTimes, faUsers, faEye } from '@fortawesome/free-solid-svg-icons';
import { InputAdornment, MenuItem, FormControl, Select, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, TextField, Typography, FormLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, CircularProgress, Grid, CardHeader, CardContent, Card, CardActions, Checkbox, FormControlLabel } from '@mui/material';
import { CompanyService } from "../services/companies/company.service";
import { HubspotService } from "../services/companies/hubspot.service";
import { lifecyclePhases, getOrderForPhase } from "../helpers/utils";
import { updateLifecycleStage, updateConversionDate, updateDatesDesVisites } from "../services/companies/poi.service";
import { alertActions } from '../redux/actions';
import { fetchAndStoreCompanies } from "../dataaccess/db";
import { ContactService } from "../services/companies/contact.service";
import { jwtDecode } from "jwt-decode";
import { format, parseISO } from 'date-fns';
import { fi, fr } from 'date-fns/locale';
import { fetchData } from "../services/companies/poi.service";
import InvoiceModal from "./InvoiceComponent";
import InvoiceListModal from "./InvoiceListComponent";
import { FreshBooksService } from "../services/freshbooks/freshbbooks.service";

const Popup = ({ feature, handleAfterDelete, handleAfterUpdate }) => {
  const dispatch = useDispatch();
  const restaurantName = feature.properties["name"];
  const address = feature.properties["address"];
  const webSite = feature.properties["site_web"];
  const idForUpdate = feature.properties["id"];
  const [enableTheLinkToTheCloud, setEnableTheLinkToTheCloud] = useState(true);
  const [currentUser, setCurrentUser] = useState(false);
  const [digitalizationContact, setDigitalizationContact] = useState(false);
  const [isDigitalizationCreation, setIsDigitalizationCreation] = useState(false);
  const [invoices, setInvoices] = useState([]);

  var timeInOpportunity = 0;

  const [lifecyclestage, setLifecyclestage] = useState(feature.properties["lifecyclestage"]);
  const [notes, setNotes] = useState(feature.properties["notes_sur_l_inscription"] || "");
  const [selectedOwner, setSelectedOwner] = useState(feature.properties["hubspot_owner_id"] || "");
  const [nombreVisite, setNombreVisite] = useState(feature.properties["nombre_de_visite"] || 0);
  const [contactForEdit, setContactForEdit] = useState(null);
  const [contactForEditId, setContactForEditId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loadVisit, setLoadVisit] = useState(false);
  const [loadSaveContact, setLoadSaveContact] = useState(false);
  const [loadFetchCotactCompanies, setLoadFetchCotactCompanies] = useState(false);
  const [loadLifeCycleStage, setLoadLifeCycleStage] = useState(false);
  const [contactToDeleteId, setContactToDeleteId] = useState(null);
  const [allDateVisiteString, setAllDateVisiteString] = useState(feature.properties["date_des_visites"] || "");
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [buttonText, setButtonText] = useState('Accord de digitalisation');
  const [openCarteDialog, setOpenCarteDialog] = useState(false);
  const [carteInformation, setCarteInformation] = useState(null);
  const [enableButton, setEnableButton] = useState(false);
  const [openSelectContactDialog, setOpenSelectContactDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [openCompaniesWithContactDialod, setOpenCompaniesWithContactDialod] = useState(false);
  const [companiesWithContact, setCompaniesWithContact] = useState([]);
  const [selectedCompaniesForContact, setSelectedCompaniesForContact] = useState(new Set());
  const [contactId, setContactId] = useState(null);
  const [searchCompaniesQuery, setSearchCompaniesQuery] = useState('');
  const [companiesResults, setCompaniesResults] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [openSearchCompaniesForContact, setOpenSearchCompaniesForContact] = useState(false);
  const [openCarteLoading, setOpenCarteLoading] = useState(false);

  const currentUserToken = localStorage.getItem("TOKEN_USER");
  const lastVisitTimeKey = `${currentUserToken}_${idForUpdate}_lastVisitTime`;
  const lastVisitTime = localStorage.getItem(lastVisitTimeKey) ? new Date(localStorage.getItem(lastVisitTimeKey)) : null;

  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [openContactListDialog, setOpenContactListDialog] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openInvoiceListDialog, setOpenInvoiceListDialog] = useState(false);
  const [contact, setContact] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    jobtitle: '',
    email: '',
  });


  //invoices 

  useEffect(() => {
    fetchInvoiceData();
  }, [idForUpdate]);

  const getFreshbooksInvoiceListByCompanyId = async (idForUpdate) => {
    return FreshBooksService.getInvoiceListByCompanyId(idForUpdate);
  };

  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const { hubspot } = useSelector(state => state);

  const formatDate = (dateString) => {
    if (!dateString) return 'X';
    const date = parseISO(dateString);

    const formattedDate = format(date, "dd/MM/yyyy à HH:mm", { locale: fr });
    const [dayMonth, time] = formattedDate.split(' à ');
    if (time === '00:00') {
      return dayMonth;
    }

    return formattedDate;
  };

  const fetchContacts = async () => {
    try {
      const contactsFromCompanyId = await ContactService.getContactsList(idForUpdate);
      setContacts(contactsFromCompanyId);
    } catch (error) {
    }
  };

  useEffect(() => {
    const user = jwtDecode(currentUserToken);
    setCurrentUser(user);
  }, [currentUserToken]);

  useEffect(() => {
    fetchContacts();
  }, [idForUpdate]);

  useEffect(() => {
    searchCompanies();
  }, [searchCompaniesQuery]);

  useEffect(() => {
    const fetchOwner = async () => {
      if (feature?.properties?.["hubspot_owner_id"]) {
        try {
          const ownerId = feature.properties["hubspot_owner_id"];
          await getOwnerById(ownerId);
        } catch (error) {
          console.error('Erreur lors de la récupération des informations du propriétaire:', error);
        }
      }
    };

    fetchOwner();
  }, [feature]);

  const getOwnerById = async (ownerId) => {
    try {
      //OPTIM
      //const companyOwner = await HubspotService.get(`hubspot/owners/${ownerId}`);
      const companyOwner = hubspot?.owners.find(ow => ow.id == ownerId)
      setSelectedOwner(companyOwner);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations du propriétaire:', error);
      throw error;
    }
  };
  const fetchDataCarteInformation = async () => {
    try {
      const lifecyclestage = feature.properties["lifecyclestage"];
      const info = await HubspotService.getHubSpotCarteInformation(idForUpdate);
      setCarteInformation(info);
      timeInOpportunity = Number(info.hs_time_in_opportunity);


      if (lifecyclestage === 'opportunity') {

        if (timeInOpportunity < TIME_LIMIT_NAVIGATE_TO_THE_CLOUD) {
          setEnableTheLinkToTheCloud(true);
          setEnableButton(true);
          setBackgroundColor('#90ee90');
          setButtonText('Converti récemment');
        } else {
          setEnableTheLinkToTheCloud(false);
          setEnableButton(true);
          const days = Math.floor(timeInOpportunity / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeInOpportunity % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          if (days < 1) {
            setBackgroundColor('#90ee90');
            setButtonText(`Converti depuis ${hours} heure${hours !== 1 ? 's' : ''}`);
          } else if (days >= 1 && days <= 7) {
            setBackgroundColor('#90ee90');
            setButtonText(`Converti depuis ${days} jour${days !== 1 ? 's' : ''} et ${hours} heure${hours !== 1 ? 's' : ''}`);
          } else if (days > 7 && days <= 12) {
            const weeks = Math.floor(days / 7);
            const remainingDays = days % 7;
            setBackgroundColor('orange');
            setButtonText(`Converti depuis ${weeks} semaine${weeks !== 1 ? 's' : ''} et ${remainingDays} jour${remainingDays !== 1 ? 's' : ''}`);
          }
          else if (days > 12) {
            const weeks = Math.floor(days / 7);
            const remainingDays = days % 7;
            setBackgroundColor('#d32f2f');
            setButtonText(`Converti depuis ${weeks} semaine${weeks !== 1 ? 's' : ''} et ${remainingDays} jour${remainingDays !== 1 ? 's' : ''}`);
          }
        }
      } else {
        setEnableButton(true);
        if (['customer', 'evangelist', 'salesqualifiedlead', '53414538', '77667414', '79354177'].includes(lifecyclestage)) {
          setEnableTheLinkToTheCloud(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataCarteInformation();
  }, [feature]);



  useEffect(() => {
    if (contactForEdit) {
      setContact({
        firstname: contactForEdit.firstname || '',
        lastname: contactForEdit.lastname || '',
        phone: contactForEdit.phone || '',
        email: contactForEdit.email || '',
        jobtitle: contactForEdit.jobtitle || '',
      });
    } else {
      // Si c'est une création, réinitialise le formulaire
      setContact({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        jobtitle: ''
      });
    }
  }, [contactForEdit]);

  const handleDirectionClick = () => {
    //const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&hl=fr`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${feature.properties['secondseenafterclient']},${feature.properties['firstseenafterclient']}&hl=fr`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleDeleteClick = () => {
    // Open the confirmation dialog
    setOpenDialog(true);
  };

  const handleEditNote = () => {
    setIsEditing(true);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await CompanyService.deleteCompany(feature.properties["id"]);
      await handleAfterDelete(feature.properties["id"]);
      await fetchAndStoreCompanies();
      dispatch(alertActions.success("Entreprise supprimée !"));
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error("Suppression échouée !"));
      console.error('Erreur:', error);
      setLoading(false);
    } finally {
      setOpenDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleHubSpotClick = () => {
    const hubSpotUrl = feature.properties["lien_fiche_hubspot"];
    window.open(hubSpotUrl, "_blank");
  };

  const handleVisitClick = () => {
    const visitUrl = feature.properties["Website"];
    window.open(visitUrl, "_blank");
  };


  const handleCloseContactDialog = () => {
    setLoadSaveContact(false);
    setOpenContactDialog(false);
    setContactForEdit(null);
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContact(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditContact = (contactId, contactProperties) => {
    setContactForEditId(contactId);
    setContactForEdit(contactProperties);
    setOpenContactDialog(true); // Ouvre la boîte de dialogue pour l'édition
  };

  const handleCreateContact = () => {
    setIsDigitalizationCreation(false);
    setContactForEditId(null);
    setContact({
      firstname: '',
      lastname: '',
      phone: '',
      jobtitle: '',
      email: ''
    });
    setOpenContactDialog(true);
  };

  const handleDeleteContact = (contactId) => {
    setContactToDeleteId(contactId); // Stocke l'ID du contact sélectionné
    setOpenConfirmationDialog(true); // Ouvre la boîte de dialogue de confirmation
  };

  const confirmDeleteContact = async () => {
    setOpenConfirmationDialog(false); // Ferme la boîte de dialogue
    try {
      if (contactToDeleteId) {
        // Appelle le service pour supprimer le contact avec l'ID stocké
        await ContactService.deleteContact(contactToDeleteId);

        setContacts(prevContacts => prevContacts.filter(contact => contact.id !== contactToDeleteId));

        await fetchContacts(); // Rafraîchit la liste des contacts après suppression
        dispatch(alertActions.success("Contact supprimé avec succès !"));
      }
    } catch (error) {
      dispatch(alertActions.error("Échec de la suppression du contact."));
    }
  };

  useEffect(() => {
    if (digitalizationContact) {
      handleDigitalizationClick(
        digitalizationContact.firstname,
        digitalizationContact.lastname,
        digitalizationContact.phone,
        digitalizationContact.email
      );
    }
  }, [digitalizationContact]);

  const handleSaveContact = async () => {
    setLoadSaveContact(true);
    try {
      const { firstname, lastname, phone, email, jobtitle } = contact;
      const restaurantName = feature.properties["name"];
      //const address = feature.properties["address"];
      const webSite = feature.properties["site_web"];
      if (isDigitalizationCreation) {
        setDigitalizationContact({ firstname, lastname, phone, email });
      }
      setOpenContactDialog(false);

      if (contactForEditId) {
        // Mode édition : mettre à jour le contact
        const response = await ContactService.updateContact(contactForEditId, {
          properties: {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            jobtitle: jobtitle,
            website: webSite,
          },
        });
        setOpenContactDialog(false);
        setSelectedContacts(new Set());
        await fetchContacts();
        setLoadSaveContact(false);
        dispatch(alertActions.success("Contact modifié avec succès !"));
      } else {
        // Mode création : créer un nouveau contact
        const response = await ContactService.createContact({
          properties: {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            jobtitle: jobtitle,
            companyId: idForUpdate,
            company: restaurantName,
            website: webSite,
          },
        });
        setContacts(prevContacts => [
          ...prevContacts,
          response
        ]);


        setLoadSaveContact(false);
        dispatch(alertActions.success("Contact enregistré avec succès !"));
      }
    } catch (error) {
      setLoadSaveContact(false);
      const errorMessage = 'Erreur lors de la création du contact';
      dispatch(alertActions.error(errorMessage));
    }
  };

  const handleCloseContactListDialog = () => {
    setOpenContactListDialog(false);
  };

  const handleMarkVisitClick = async () => {
    setLoadVisit(true);
    const currentTime = new Date();
    const currentDateString = currentTime.toISOString().slice(0, 19).replace('T', ' ');

    if (lastVisitTime) {
      const elapsedTime = (currentTime - new Date(lastVisitTime)) / 1000 / 60; // Temps écoulé en minutes
      const remainingTime = Math.max(0, 30 - Math.floor(elapsedTime)); // Temps restant en minutes
      if (remainingTime > 0) {
        dispatch(alertActions.error(`Veuillez attendre encore ${remainingTime} minute${remainingTime > 1 ? 's' : ''} avant d'enregistrer une nouvelle visite.`));
        setLoadVisit(false);
        return;
      }
    }
    try {
      // Incrémente le nombre de visites et définit le timestamp de la dernière visite
      const updatedNombreVisite = parseInt(nombreVisite) + 1;
      const updatedLastVisiteTimestamp = new Date();
      // Met à jour les états locaux
      setNombreVisite(nombreVisite + 1);
      localStorage.setItem(lastVisitTimeKey, updatedLastVisiteTimestamp.toISOString());
      const newAllDateVisiteString = allDateVisiteString ? `${allDateVisiteString} | ${currentDateString}` : currentDateString;

      // Met à jour le nombre de visites et le timestamp dans HubSpot et l'API

      const hubspotResponse = await HubspotService.updateNombreVisite(idForUpdate, updatedNombreVisite, lifecyclestage, newAllDateVisiteString);
      const apiResponse = await CompanyService.updateNombreVisite(idForUpdate, hubspotResponse.properties.nombre_de_visite, newAllDateVisiteString);
      const data = { companyId: idForUpdate, lifecyclestage: 'visit' }
      await CompanyService.insertHistory(data);

      feature.properties['nombre_de_visite'] = hubspotResponse.properties.nombre_de_visite;
      feature.properties['date_des_visites'] = hubspotResponse.properties.date_des_visites;
      setNombreVisite(hubspotResponse.properties.nombre_de_visite);
      await updateDatesDesVisites(idForUpdate, hubspotResponse.properties.date_des_visites);

      // Met à jour le timestamp de la dernière visite dans HubSpot et l'API
      const hubspotResponseLastVisit = await HubspotService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
      const apiResponseLastVisit = await CompanyService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);

      // Met à jour les propriétés de la feature avec les données retournées
      feature.properties['lastvisit'] = hubspotResponseLastVisit.properties.lastvisit;

      // Affiche un message de succès
      await await getOwnerById('current_id');
      dispatch(alertActions.success("Visite enregistrée !"));

      setLoadVisit(false);
    } catch (error) {
      // Affiche un message d'erreur en cas de problème
      setLoadVisit(false);
      dispatch(alertActions.error("Enregistrement échoué !"));
    }
  };

  const fetchInvoiceData = async () => {
    try {
      const invoicesByCompanyId = await FreshBooksService.getInvoiceListByCompanyId(idForUpdate);
      setInvoices(invoicesByCompanyId);
    } catch (error) {
      console.error('Error fetching FreshBooksInvoice data:', error);
    }
  };

  const isAnyRelevantFieldFilled = () => {
    return contact.firstname || contact.lastname || contact.email || contact.phone;
  };

  const handleLifecyclestageChange = async (param) => {
    setLoadLifeCycleStage(true);
    const currentTime = new Date();
    const newPhase = typeof param === "string" ? param : param.target.value;
    try {
      const hubspotResponse = await HubspotService.updateLifecyclestage(idForUpdate, newPhase, lifecyclestage);
      const apiResponse = await CompanyService.updateLifecyclePhase(idForUpdate, hubspotResponse.properties.lifecyclestage, lifecyclestage);
      const data = { companyId: idForUpdate, lifecyclestage: newPhase }
      await CompanyService.insertHistory(data);
      if (apiResponse && (lastVisitTime === null || (currentTime - lastVisitTime >= 30 * 60 * 1000))) {
        await handleMarkVisitClick();
      }
      else {
        const updatedLastVisiteTimestamp = new Date();
        const hubspotResponseLastVisit = await HubspotService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
        const apiResponseLastVisit = await CompanyService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
        feature.properties['lastvisit'] = hubspotResponseLastVisit.properties.lastvisit;
      }
      await updateLifecycleStage(idForUpdate, hubspotResponse.properties.lifecyclestage);

      const columnName = columnMapping[lifecyclePhases.find(phase => phase.value === newPhase).label];
      if (columnName) {
        const currentDate = new Date();
        currentDate.setUTCHours(0, 0, 0, 0);
        const formattedDate = currentDate.toISOString();

        await HubspotService.updateConversionDate(idForUpdate, columnName, formattedDate);
        await CompanyService.updateConversionDate(idForUpdate, columnName, formattedDate);
        await updateConversionDate(idForUpdate, columnName, new Date())
      }

      feature.properties['lifecyclestage'] = hubspotResponse.properties.lifecyclestage;
      setLifecyclestage(apiResponse.lifecyclestage);

      await getOwnerById('current_id');
      await handleAfterUpdate();
      await fetchDataCarteInformation();
      dispatch(alertActions.success("Phase de cycle de vie enregistrée !"));
      setLoadLifeCycleStage(false);
    } catch (error) {
      console.error('Erreur:', error);
      dispatch(alertActions.error("Enregistrement échoué !"));
      setLoadLifeCycleStage(false);
    }
  };

  const handleSaveNote = async (event) => {
    try {
      feature.properties["notes_sur_l_inscription"] = notes;
      const hubspotResponse = await HubspotService.updateNotes(idForUpdate, notes);
      const apiResponse = await CompanyService.updateNotes(idForUpdate, notes);
      setNotes(notes);
      setIsEditing(false);
      dispatch(alertActions.success("Note enregistrée !"));
    } catch (error) {
      setIsEditing(false);
      dispatch(alertActions.success("Enregistrement échoué"));
      console.error('Erreur:', error);
    }
  };

  const handleShowContactList = () => {
    setOpenContactListDialog(true);
  };

  const openCartePopup = async () => {
    setOpenCarteLoading(true);
    try {
      // const response = await HubspotService.getHubSpotCarteInformation(feature.properties["id"]);
      if (carteInformation) {
        //  setCarteInformation(response); // Mettre à jour l'état avec les données de l'API
        setOpenCarteLoading(false);
        setOpenCarteDialog(true); // Ouvrir le dialogue
      } else {
        setOpenCarteLoading(false);
        console.error('Aucune donnée trouvée dans la réponse de l\'API');
      }
    } catch (error) {
      setOpenCarteLoading(false);
      console.error('Erreur lors de l\'appel à l\'API HubSpot:', error);

    }
  };

  const handleDigitalizationClick = async (firstname, lastname, phone, email) => {
    setIsDigitalizationCreation(true);
    let contactFirstName, contactLastName, contactEmail, contactPhone;
    if (firstname) {
      contactFirstName = firstname;
      contactLastName = lastname;
      contactEmail = email;
      contactPhone = phone;
    }
    else if (contacts.length === 0) {
      setOpenContactDialog(true);
      return;
    }
    else if (contacts.length === 1) {
      contactFirstName = contacts[0].properties.firstname;
      contactLastName = contacts[0].properties.lastname;
      contactEmail = contacts[0].properties.email;
      contactPhone = contacts[0].properties.phone;
    }
    else {
      setOpenSelectContactDialog(true);
      return;
    }
    setOpenContactDialog(false);

    const token = await HubspotService.getToken();
    const encryptedEmail = token.encryptedEmail
    const recordId = feature.properties["id"];
    const city = feature.properties["city"];
    const restaurantName = feature.properties["name"];
    const postalCode = feature.properties["zip"];
    const address = feature.properties["address"];


    //Mettre à jour le cyle de vie
    if (lifecyclestage != "opportunity") {
      setLoadLifeCycleStage(true);
      const newPhase = "opportunity";
      const currentDate = new Date();
      const newDate = currentDate;
      const hubspotResponse = await HubspotService.updateLifecyclestage(idForUpdate, newPhase, lifecyclestage);
      const apiResponse = await CompanyService.updateLifecyclePhase(idForUpdate, hubspotResponse.properties.lifecyclestage, lifecyclestage);
      if (apiResponse && (lastVisitTime === null || (currentDate - lastVisitTime >= 30 * 60 * 1000))) {
        await handleMarkVisitClick();
      }
      await updateLifecycleStage(idForUpdate, hubspotResponse.properties.lifecyclestage);
      setLifecyclestage(apiResponse.lifecyclestage);
      currentDate.setUTCHours(0, 0, 0, 0);
      const formattedDate = currentDate.toISOString();

      await HubspotService.updateConversionDate(idForUpdate, "date_de_conversion_en_accord", formattedDate);
      await CompanyService.updateConversionDate(idForUpdate, "date_de_conversion_en_accord", formattedDate);
      const data = { companyId: idForUpdate, lifecyclestage: newPhase }
      await CompanyService.insertHistory(data);
      setLoadLifeCycleStage(false);
      await updateConversionDate(idForUpdate, "date_de_conversion_en_accord", newDate);
      await fetchDataCarteInformation();
    }

    const url = `https://wiicmenu-qrcode.com/Admin/dist/creat-cloud.php?record=${encodeURIComponent(recordId)}&ville=${encodeURIComponent(city)}&nom_restaurant=${encodeURIComponent(restaurantName)}&code_postal=${encodeURIComponent(postalCode)}&adresse=${encodeURIComponent(address)}&token=${encryptedEmail}&contactLastName=${contactLastName}&contactFirstName=${contactFirstName}&contactEmail=${contactEmail}&contactPhone=${contactPhone}`;
    window.open(url, '_blank');
  };
  const isOpportunity = ['opportunity', 'salesqualifiedlead', '53414538', '77667414', '79354177'].includes(lifecyclestage) && enableTheLinkToTheCloud === false;
  const isClientFreemiumOrPremium = ['customer', 'evangelist'].includes(lifecyclestage) && enableTheLinkToTheCloud === false;
  const handleCompanyContactCheckboxChange = (companyId, companyName) => {
    setSelectedCompaniesForContact(prevSelected => {
      const updatedSelected = new Set(prevSelected);
      if (updatedSelected.has(companyId)) {
        updatedSelected.delete(companyId); // Retire si déjà sélectionné
        dispatch(alertActions.error(`Attention, vous avez désassocier l'entreprise ${companyName} du contact machin !`));
      } else {
        updatedSelected.add(companyId); // Ajoute si non sélectionné
      }
      return updatedSelected;
    });
  };

  const handleShowCompaniesFromContactId = async (id) => {
    setLoadFetchCotactCompanies(prevState => ({
      ...prevState,
      [id]: true,
    }));
    setContactId(id);
    try {
      const response = await fetchCompaniesAssociedContactId(id);
      if (Array.isArray(response)) {
        setCompaniesWithContact(response);
        const associatedCompanyIds = new Set(response.map(company => company.id));
        setSelectedCompaniesForContact(associatedCompanyIds);
      } else {
        setCompaniesWithContact([]);
        setSelectedCompaniesForContact(new Set());
      }
      setOpenCompaniesWithContactDialod(true);
      setLoadFetchCotactCompanies(prevState => ({
        ...prevState,
        [id]: false,
      }));
    } catch (error) {
      setLoadFetchCotactCompanies(prevState => ({
        ...prevState,
        [id]: false,
      }));
      setCompaniesWithContact([]);
    }
  }

  const fetchCompaniesAssociedContactId = async (contactId) => {
    return await ContactService.fetchCompaniesAssociedContactId(contactId);
  };

  const handleSaveContactsForCompanie = () => {
    if (!contactId) return;
    const uncheckedCompanies = companiesWithContact
      .filter(company => !selectedCompaniesForContact.has(company.id))
      .map(company => company.id);
    ContactService.disassociateContactCompanies(contactId, uncheckedCompanies)
      .then(async () => {
        setOpenCompaniesWithContactDialod(false);
        await fetchContacts();
      })
      .catch(error => console.error("Erreur lors de la mise à jour des associations :", error));
  };
  let debounceTimer;
  const searchCompanies = async () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
      if (searchCompaniesQuery.length >= 3) {
        const filters = {
          companyNames: [{ name: searchCompaniesQuery }]
        };
        const response = await fetchData({ centerCoordinates: { filters } });

        const companies = response.features
          .map((feature) => feature.properties)
          .filter((company) =>
            company.name.toLowerCase().includes(searchCompaniesQuery.toLowerCase())
          );
        setCompaniesResults(companies);
      }
    }, 500);
  };

  const handleSelectCompany = (companyId) => {
    setSelectedCompanies((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(companyId)) {
        newSelection.delete(companyId); // Désélectionner si déjà sélectionnée
      } else {
        newSelection.add(companyId); // Ajouter à la sélection
      }
      return newSelection;
    });
  };

  const associateContactWithCompanies = () => {
    setOpenSearchCompaniesForContact(true)
  }

  const handleAssociateCompaniesWithContactSave = async () => {
    const companyIds = Array.from(selectedCompanies);
    if (companyIds.length > 0) {
      await HubspotService.associateCompaniesWithContact(contactId, companyIds);
      setSelectedCompanies(new Set());
    }
    setOpenSearchCompaniesForContact(false);
  }

  const handleSearchChange = (e) => {
    setSearchCompaniesQuery(e.target.value);
  };

  const handleOpenDialog = () => {
    setOpenInvoiceDialog(!openInvoiceDialog);
  }

  const handleCreateInvoice = () => {
    setOpenInvoiceDialog(true);
  };

  return (
    <div id="popup-container">
      {/* <h3><strong>{restaurantName}</strong></h3> */}
      <h4><strong>{address}</strong></h4>

      <Box mt={2} mb={2}>
        <FormControl fullWidth>
          <FormLabel sx={{ marginBottom: '4px', fontSize: '12px', fontWeight: 'bold' }}>
            Propriétaire
          </FormLabel>
          <Typography
            variant="body1"
            sx={{
              padding: '12px 16px',
              fontSize: '16px',
              fontWeight: '500',
              borderRadius: '4px',
            }}
          >
            {selectedOwner && (
              `${selectedOwner.firstName || ''} ${selectedOwner.lastName || ''}`.trim() ||
              'Propriétaire'
            )}
          </Typography>
        </FormControl>
      </Box>

      <p>
        {isEditing ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Note sur l'inscription"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              fullWidth
              variant="outlined"
              rows={2}
              InputProps={{
                style: { fontSize: '0.775rem' }
              }}
            />
            <FontAwesomeIcon
              icon={faSave}
              className="icon"
              size="lg"
              onClick={handleSaveNote}
              title="Sauvegarder"
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            />
          </span>
        ) : (
          <>
            <strong>Note sur l'inscription&nbsp;: </strong>
            <span>
              {isExpanded ? (
                notes
              ) : (
                <>
                  {notes.slice(0, 20)}
                  {notes.length > 20 && '...'}
                </>
              )}
              {notes.length > 20 && (
                <span
                  onClick={handleToggleExpand}
                  style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}>
                  {isExpanded ? "Voir moins" : "Voir plus"}
                </span>
              )}
              <FontAwesomeIcon
                icon={faEdit}
                className="icon"
                size="lg"
                onClick={handleEditNote}
                title="Modifier"
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </span>
          </>
        )}
      </p>

      <Box mt={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel>Phase de cycle de vie</InputLabel>
          <Select
            value={lifecyclestage}
            label="Phase de cycle de vie"
            onChange={handleLifecyclestageChange}
            style={{ color: getColorForPhase(lifecyclestage) }}
            IconComponent={loadLifeCycleStage ? () => null : undefined}
            endAdornment={
              loadLifeCycleStage && (
                <InputAdornment position="end">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <CircularProgress size={20} />
                  </div>
                </InputAdornment>
              )
            }
          >
            {lifecyclePhases
              .filter(phase => phase.order >= getOrderForPhase(lifecyclestage))
              .map(phase => (
                <MenuItem key={phase.value} value={phase.value}>
                  <span style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: phase.color,
                    marginRight: '8px'
                  }}></span>
                  {phase.label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>

      <div className="button-container">
        <div className="icon-button" onClick={handleDirectionClick} title="Ouvrir la direction dans Google Maps">
          <img
            src="/assets/images/Google-icon-logo.png"
            alt="HubSpot Logo"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        <div className="icon-button" onClick={handleHubSpotClick} title="Ouvrir la fiche HubSpot">
          <img
            src="/assets/images/Hubspot-icon-logo.png"
            alt="HubSpot Logo"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        {webSite &&
          <div className="icon-button" onClick={handleVisitClick} title="Visiter le site web">
            <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
          </div>
        }
        {/* Nouveau bouton "Créer un contact" */}
        <div className="icon-button" onClick={handleShowContactList} title="Créer un contact">
          <FontAwesomeIcon icon={faUsers} size="lg" />
        </div>

        {/* Dialogue pour afficher la liste des contacts */}
        <Dialog
          open={openContactListDialog}
          onClose={handleCloseContactListDialog}
          PaperProps={{ style: { borderRadius: 5 } }}
          maxWidth="md"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              width: '600px',
              height: '400px',
              maxWidth: '100%',
            },
          }}
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            Liste des Contacts
            <IconButton
              style={{ position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseContactListDialog}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            marginLeft: '-10px', // Spacing from the left edge
                            padding: '0', // Remove extra padding around the icon
                            width: '30px', // Control the button's size
                            height: '30px',
                            border: '1px solid', // Defined border
                            borderRadius: '50%', // Circular shape
                            minWidth: 'unset', // Prevent the button from expanding
                            display: 'flex', // Make button a flex container to center the icon
                            justifyContent: 'center', // Center the icon horizontally
                            alignItems: 'center', // Center the icon vertically
                          }}
                          onClick={handleCreateContact}
                          title="Ajouter un Contact"
                        >
                          <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: '14px' }} />
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Poste</TableCell>
                    <TableCell>E-mail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((contact, index) => (
                    <TableRow key={index}>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedContacts.has(index)}
                          onChange={() => toggleContactSelection(index)}
                        />
                      </TableCell> */}
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            style={{
                              marginLeft: '-15px',
                              padding: '0',
                              width: '30px',
                              height: '30px',
                              border: 'none',
                              borderRadius: '50%',
                              minWidth: 'unset',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: '2px' // Space between the two buttons
                            }}
                            onClick={() => handleDeleteContact(contact.id)}
                            title="Suppression des Contacts"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '14px' }} />
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              marginLeft: '0px',
                              padding: '0',
                              width: '30px',
                              height: '30px',
                              border: 'none',
                              borderRadius: '50%',
                              minWidth: 'unset',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => handleEditContact(contact.id, contact.properties)}
                            title="Modifier un Contact"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: '14px' }} />
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              marginLeft: '0px',
                              padding: '0',
                              width: '30px',
                              height: '30px',
                              border: 'none',
                              borderRadius: '50%',
                              minWidth: 'unset',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => handleShowCompaniesFromContactId(contact.id)}
                            title="Voir les entreprises associées"
                          >
                            {loadFetchCotactCompanies[contact.id] ? (
                              <CircularProgress size={14} color="inherit" />
                            ) : (
                              <FontAwesomeIcon icon={faEye} style={{ fontSize: '14px' }} />
                            )}
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>{contact.properties.firstname}</TableCell>
                      <TableCell>{contact.properties.lastname}</TableCell>
                      <TableCell>{contact.properties.phone}</TableCell>
                      <TableCell>{contact.properties.jobtitle}</TableCell>
                      <TableCell>{contact.properties.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        {/* Dialogue pour créer un nouveau contact */}
        <Dialog
          open={openContactDialog}
          onClose={handleCloseContactDialog}
          maxWidth="xs"
          PaperProps={{ style: { borderRadius: 5 } }} // Style du dialogue
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            {contactForEdit ? 'Modifier le Contact' : 'Créer un Contact'}
          </DialogTitle>

          <DialogContent>
            <TextField
              label="Prénom"
              name="firstname"
              variant="outlined"
              value={contact.firstname}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Nom"
              name="lastname"
              variant="outlined"
              value={contact.lastname}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Téléphone"
              name="phone"
              variant="outlined"
              value={contact.phone}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="E-mail"
              name="email"
              variant="outlined"
              value={contact.email}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Poste"
              name="jobtitle"
              variant="outlined"
              value={contact.jobtitle}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <DialogActions style={{ justifyContent: 'center', marginTop: 15, marginBottom: 0 }}>
              <Button onClick={handleCloseContactDialog} color="warning" variant="outlined" style={{ width: 150 }}>
                Annuler
              </Button>
              <Button
                onClick={handleSaveContact}
                color="primary"
                variant="outlined"
                style={{ width: 150 }}
                disabled={!isAnyRelevantFieldFilled()}
              >
                {!loadSaveContact && "Enregistrer"}
                {loadSaveContact && <CircularProgress size={20} color="inherit" />}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* Nouveau bouton "Visiter" */}
        <div className="icon-button" onClick={handleMarkVisitClick} title="Visiter">
          {!loadVisit && <FontAwesomeIcon icon={faCheckCircle} size="lg" />}
          {loadVisit && <CircularProgress size={20} color="inherit" />}
        </div>
      </div>
      {isClientFreemiumOrPremium ? (
        <Button
          color="inherit"
          variant="outlined"
          fullWidth
          onClick={() => {
            setOpenInvoiceListDialog(true)
            setOpenCarteDialog(false)
          }}
          sx={{ mt: 1, height: '36px' }}
          disabled={!enableButton}
        >
          <span>Facture</span>
        </Button>
      ) : (
        <Button
          color={isOpportunity ? "success" : "secondary"}
          variant="outlined"
          fullWidth
          onClick={isOpportunity ? openCartePopup : () => handleDigitalizationClick(null, null, null, null)}
          sx={{ mt: 1, height: '36px' }}
          disabled={!enableButton} // Désactive le bouton si enableButton est false
        >
          {!openCarteLoading && (
            <span>{isOpportunity ? buttonText : 'Accord de digitalisation'}</span>
          )}
          {!enableButton && (
            <Box ml={0.75}> {/* Décale de 2 pixels à droite */}
              <CircularProgress size={13} color="inherit" />
            </Box>
          )}
        </Button>
      )}


      <Button
        color="error"
        variant="outlined"
        fullWidth
        onClick={handleDeleteClick}
        sx={{ mt: 1, height: '36px' }}
      >
        <span>Supprimer</span>
      </Button>

      <Dialog
        maxWidth="xs"
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        PaperProps={{ style: { borderRadius: 10, padding: '15px 0' } }}
      >
        <DialogTitle style={{ padding: 0, textAlign: 'center' }}>Confirmation</DialogTitle>
        <DialogContent style={{ padding: 0, textAlign: 'center' }}>
          <DialogContentText style={{ padding: 0, textAlign: 'center' }}>
            <p>Êtes-vous sûr de vouloir supprimer cette entreprise ?<br />Cette action est irreversible.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 0, justifyContent: 'center' }}>
          <Button onClick={handleCloseDialog} color="error" variant="outlined" style={{ width: 150 }}>
            Non
          </Button>
          <Button
            variant="outlined"
            color="success"
            fullWidth
            onClick={confirmDelete}
            sx={{ width: 150 }}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {!loading && <span>Oui</span>}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        PaperProps={{ style: { borderRadius: 5 } }}
      >
        <DialogTitle>Confirmation de la Suppression</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer les contacts sélectionnés ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDeleteContact} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour la carte */}
      <Dialog
        open={openCarteDialog}
        onClose={() => setOpenCarteDialog(false)}
        sx={{ '& .MuiDialog-paper': { width: '400px', maxWidth: '400px' } }}
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              ///backgroundColor: '#f1f1f1', 
              padding: '10px',
              borderRadius: '4px',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
          >
            Details de la Carte
          </Box>
        </DialogTitle>
        <DialogContent>
          {carteInformation ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Carte fait le : {formatDate(carteInformation?.impression_design_plexiglass)}
                        </Typography>
                      </Grid>
                      <Box mb={2} />
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Remplissage carte :{' '}
                          {carteInformation?.users?.remplissage_carte?.firstName || carteInformation?.users?.remplissage_carte?.lastName
                            ? `${carteInformation?.users?.remplissage_carte?.firstName || ''} ${carteInformation?.users?.remplissage_carte?.lastName || ''}`.trim()
                            : 'X'}
                        </Typography>
                      </Grid>
                      <Box mb={2} />
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Design :{' '}
                          {carteInformation?.users?.design?.firstName || carteInformation?.users?.design?.lastName
                            ? `${carteInformation?.users?.design?.firstName || ''} ${carteInformation?.users?.design?.lastName || ''}`.trim()
                            : 'X'}
                        </Typography>
                      </Grid>
                      <Box mb={2} />
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Design fait le : {formatDate(carteInformation?.retour_installation_design)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center', width: '100%' }}>
                    <Button
                      href={carteInformation?.lien_du_menu_qrcode || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={!carteInformation?.lien_du_menu_qrcode}
                      variant="outlined"
                      color="success"
                      sx={{ width: '100%' }}
                    >
                      Menu QRCODE
                    </Button>
                  </CardActions>
                  <CardActions sx={{ justifyContent: 'center', width: '100%' }}>
                    <Button
                      onClick={() => {
                        setOpenInvoiceDialog(true)
                        setOpenCarteDialog(false)
                      }}
                      variant="outlined"
                      color="primary"
                      sx={{ width: '100%' }}
                    >
                      INSTALLER
                    </Button>
                  </CardActions>
                  <CardActions sx={{ justifyContent: 'center', width: '100%' }}>
                    <Button
                      onClick={() => setOpenCarteDialog(false)}
                      variant="outlined"
                      color="warning"
                      sx={{ width: '100%' }}
                    >
                      Fermer
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">Chargement des données...</Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Choisir un Contact */}
      <Dialog
        open={openSelectContactDialog}
        onClose={() => setOpenSelectContactDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: '400px',
            minHeight: 300,
            maxWidth: '100%',
            justifyContent: 'center'
          },
        }}


      >
        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>Choisir un Contact</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedContact}
            onChange={(event) => setSelectedContact(event.target.value)}
            displayEmpty
            sx={{ mb: 0 }}
          >
            {contacts.map(contact => (
              <MenuItem key={contact.id} value={contact}>
                {contact.properties.firstname} {contact.properties.lastname}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            mb: 2
          }}
        >
          <Button onClick={() => setOpenSelectContactDialog(false)} color="warning" variant="outlined" style={{ width: 150 }}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              handleDigitalizationClick(
                selectedContact?.properties.firstname || null,
                selectedContact?.properties.lastname || null,
                selectedContact?.properties.phone || null,
                selectedContact?.properties.email || null
              );
              setOpenSelectContactDialog(false);
            }}
            color="primary" variant="outlined" style={{ width: 150 }}
            disabled={!selectedContact}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour afficher la liste des entreprises */}
      <Dialog
        open={openCompaniesWithContactDialod}
        onClose={() => setOpenCompaniesWithContactDialod(false)}
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '400px',
            height: 'auto',
            maxWidth: '100%',
          },
        }}
      >
        <DialogTitle align='center'>
          <strong>Nom des entreprises associées</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpenCompaniesWithContactDialod(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey',
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {companiesWithContact?.map((company) => (
            <FormControlLabel
              key={company.id}
              control={
                <Checkbox
                  checked={selectedCompaniesForContact.has(company.id)}
                  onChange={() => handleCompanyContactCheckboxChange(company.id, company.name)}
                />
              }
              label={company.name}
            />
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
          <Grid container direction="column" spacing={2}>
            {/* Bouton "Associer d'autres entreprises" au-dessus */}
            <Grid item>
              <Button
                onClick={associateContactWithCompanies}
                color="primary"
                variant="outlined"
                fullWidth
              >
                Associer d'autres entreprises
              </Button>
            </Grid>

            {/* Espacement entre les boutons */}
            <Grid item>
              <Button
                onClick={() => setOpenCompaniesWithContactDialod(false)}
                color="warning"
                variant="outlined"
                fullWidth
              >
                Annuler
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSaveContactsForCompanie}
                color="primary"
                variant="outlined"
                fullWidth
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSearchCompaniesForContact}
        onClose={() => {
          setOpenSearchCompaniesForContact(false);
          setSelectedCompanies(new Set());
        }}
        PaperProps={{ style: { borderRadius: 10 } }} // Border radius
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '350px', // Largeur du dialogue
            height: 'auto', // Hauteur automatique
            maxWidth: '100%', // Empêcher le dépassement
          },
        }}
      >
        {/* Dialog Title */}
        <DialogTitle align="center" sx={{ position: 'relative' }}>
          <strong>Associer des entreprises</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpenSearchCompaniesForContact(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey',
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent>
          <TextField
            label="Rechercher des entreprises"
            value={searchCompaniesQuery}
            variant="outlined"
            sx={{ mt: 1 }}
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              style: { height: '54px' }
            }}
          />
          <Grid container direction="column" spacing={0.1}>
            {companiesResults.length > 0 ? (
              companiesResults.map((company) => (
                <Grid item key={company.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedCompanies.has(company.id)}
                        onChange={() => handleSelectCompany(company.id)}
                      />
                    }
                    label={company.name}
                  />
                </Grid>
              ))
            ) : (
              // Centrage du message avec Grid
              <Grid item container justifyContent="center" alignItems="center" style={{ height: '50px' }}>
                <Typography variant="body1" color="textSecondary">
                  Aucune entreprise trouvée
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        {/* Dialog Actions - Buttons */}
        <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button
                onClick={handleAssociateCompaniesWithContactSave}
                color="primary"
                variant="outlined"
                fullWidth
              >
                Valider
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <InvoiceModal
        open={openInvoiceDialog}
        onClose={handleOpenDialog}
        contacts={contacts}
        company={feature.properties}
        restaurantName={restaurantName}
        addressepostal={address}
        lifecyclestage={lifecyclestage}
        idCompany={idForUpdate}
        handleLifecyclestageChange={handleLifecyclestageChange}
        refreshInvoiceList={fetchInvoiceData}
        filteredInvoices={invoices}
      />
      <InvoiceListModal
        open={openInvoiceListDialog}
        onClose={() => setOpenInvoiceListDialog(false)}
        openCreateInvoice={handleCreateInvoice}
        filteredInvoices={invoices}
        companyId={idForUpdate}
      />
    </div>
  );
};

export default Popup;
