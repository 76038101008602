import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const ModelPieInvoice = ({ invoicesList }) => {
    const paymentMethodMapping = {
        'Check': 'Chèque',
        'Cash': 'Espèces',
        'Bank Transfer': 'Virement bancaire',
        'Credit Card': 'Carte bancaire',
    };

    const paymentTotals = {
        'Chèque': 0,
        'Espèces': 0,
        'Virement bancaire': 0,
        'Carte bancaire': 0,
    };

    invoicesList.forEach(invoice => {
        const paymentMode = invoice.fbInvoicePayementMode;
        
        if (paymentMode && paymentMethodMapping[paymentMode]) {
            const totalTTC = parseFloat(JSON.parse(invoice?.fbProductsDetails)?.cost?.totalTTC);
            paymentTotals[paymentMethodMapping[paymentMode]] += totalTTC;
        }
    });

    // Vérifier si des totaux sont supérieurs à zéro
    const hasData = Object.values(paymentTotals).some(total => total > 0);

    // Si aucune donnée n'est disponible, on prépare une seule donnée "No Data"
    const filteredMethods = hasData 
        ? Object.keys(paymentTotals)
            .filter(method => paymentTotals[method] > 0)
            .map(method => ({
                label: method,
                total: paymentTotals[method],
                color: getColorForPaymentMethod(method),
            }))
        : [{ label: 'No Data', total: 0.01, color: '#cccccc' }]; // Afficher un graphique gris

    const filteredLabels = filteredMethods.map(method => method.label);
    const filteredTotals = filteredMethods.map(method => method.total);
    const backgroundColors = filteredMethods.map(method => method.color);

    const data = {
        labels: filteredLabels,
        datasets: [
            {
                label: 'Méthodes de Paiement',
                data: filteredTotals,
                backgroundColor: backgroundColors,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, 
            },
        },
        cutout: '50%', 
    };

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '300px',
                maxHeight: '300px',
                margin: '0 auto',
                marginTop: 15,
                position: 'relative'
            }}>
                <Pie data={data} options={options} />
            </div>
            {/* Légende avec 2 colonnes */}
            <div style={{
                marginTop: '10px',
                textAlign: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '16px',
                fontSize: 11
            }}>
                {filteredMethods.map((method, index) => (
                    <div key={method.label} style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '2px',
                        width: '45%',
                        justifyContent: 'flex-start',
                    }}>
                        {/* Couleur associée au mode de paiement */}
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: method.color,
                                marginRight: '10px',
                                borderRadius: '50%',
                            }}
                        ></div>
                        {/* Nom du mode de paiement et le montant */}
                        <span>{method.label}: {method.total.toFixed(2)} €</span>
                    </div>
                ))}
            </div>
        </>
    );
};

// Fonction pour récupérer la couleur correspondant à chaque mode de paiement
const getColorForPaymentMethod = (method) => {
    switch (method) {
        case 'Chèque':
            return '#4caf50'; 
        case 'Espèces':
            return '#ff9800'; 
        case 'Virement bancaire':
            return '#2196f3'; 
        case 'Carte bancaire':
            return '#f44336'; 
        default:
            return '#cccccc'; 
    }
};

export default ModelPieInvoice;
