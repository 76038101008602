import React, { useState, useEffect } from "react";
import { Typography, Box, Switch, FormControlLabel } from "@mui/material";
import { FreshBooksService } from "../services/freshbooks/freshbbooks.service";
import { jwtDecode } from 'jwt-decode';

const SettingsPage = ({ toggleDarkMode, darkMode }) => {
    const [freshbooksSynchroInvoiceCreate, setFreshbooksSynchroInvoiceCreate] = useState(false);
    const [freshbooksSynchroInvoiceUpdate, setFreshbooksSynchroInvoiceUpdate] = useState(false);
    const [freshbooksSynchroInvoicePayment, setFreshbooksSynchroInvoicePayment] = useState(false);
    const [freshbooksSynchroInvoiceDeletion, setFreshbooksSynchroInvoiceDeletion] = useState(false);
    const [callbacksList, setCallbacksList] = useState({});
    const [currentUser] = useState(jwtDecode(localStorage.getItem('TOKEN_USER')) || {});

    useEffect(() => {
        if (currentUser.profile === 'SUPERADMIN') {
            FreshBooksService.getCallbacksList()
                .then(
                    (res) => {
                        setCallbacksList(res);

                        setFreshbooksSynchroInvoiceCreate(res.callbacks?.find(cb => cb.event === "invoice.create") != null);
                        setFreshbooksSynchroInvoiceUpdate(res.callbacks?.find(cb => cb.event === "invoice.update") != null);
                        setFreshbooksSynchroInvoiceDeletion(res.callbacks?.find(cb => cb.event === "invoice.delete") != null);
                        setFreshbooksSynchroInvoicePayment(res.callbacks?.find(cb => cb.event === "payment.create") != null);
                    }
                );
        }
    }, [freshbooksSynchroInvoiceCreate, freshbooksSynchroInvoiceUpdate, freshbooksSynchroInvoicePayment, freshbooksSynchroInvoiceDeletion]);

    const toggleFbSynchroInvoiceOnCreate = async () => {
        if (!freshbooksSynchroInvoiceCreate) {
            await FreshBooksService.enableSynchro("invoice.create").then(() => setFreshbooksSynchroInvoiceCreate(!freshbooksSynchroInvoiceCreate));
        } else {
            const callback = callbacksList.callbacks?.find(cb => cb.event === "invoice.create");
            await FreshBooksService.deleteCallback(callback.callbackid).then(() => setFreshbooksSynchroInvoiceCreate(!freshbooksSynchroInvoiceCreate));
        }
    }

    const toggleFbSynchroInvoiceOnUpdate = async () => {
        if (!freshbooksSynchroInvoiceUpdate) {
            await FreshBooksService.enableSynchro("invoice.update").then(() => setFreshbooksSynchroInvoiceUpdate(!freshbooksSynchroInvoiceUpdate));
        } else {
            const callback = callbacksList.callbacks?.find(cb => cb.event === "invoice.update");
            await FreshBooksService.deleteCallback(callback.callbackid).then(() => setFreshbooksSynchroInvoiceUpdate(!freshbooksSynchroInvoiceUpdate));
        }
    }

    const toggleFbSynchroInvoiceOnDelete = async () => {
        if (!freshbooksSynchroInvoiceDeletion) {
            await FreshBooksService.enableSynchro("invoice.delete").then(() => setFreshbooksSynchroInvoiceDeletion(!freshbooksSynchroInvoiceDeletion));
        } else {
            const callback = callbacksList.callbacks?.find(cb => cb.event === "invoice.delete");
            await FreshBooksService.deleteCallback(callback.callbackid).then(() => setFreshbooksSynchroInvoiceDeletion(!freshbooksSynchroInvoiceDeletion));
        }
    }

    const toggleFbSynchroInvoiceOnPayment = async () => {
        if (!freshbooksSynchroInvoicePayment) {
            await FreshBooksService.enableSynchro("payment.create").then(() => setFreshbooksSynchroInvoicePayment(!freshbooksSynchroInvoicePayment));
        } else {
            const callback = callbacksList.callbacks?.find(cb => cb.event === "payment.create");
            await FreshBooksService.deleteCallback(callback.callbackid).then(() => setFreshbooksSynchroInvoicePayment(!freshbooksSynchroInvoicePayment));
        }
    }

    //
    return (
        <Box
            sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
            }}
        >
            <Typography variant="h5" gutterBottom>
                Préférences
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={darkMode}
                            onChange={toggleDarkMode}
                            color="success"
                        />
                    }
                    label="Mode sombre"
                />
            </Box>

            {currentUser.profile === 'SUPERADMIN' &&
                <>
                    <Typography variant="h5" gutterBottom mt={5}>
                        Sychronisation Freshbooks vers Map
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mt: 1
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freshbooksSynchroInvoiceCreate}
                                    onChange={toggleFbSynchroInvoiceOnCreate}
                                    color="success"
                                />
                            }
                            label="A la création d'une facture"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mt: 2
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freshbooksSynchroInvoiceUpdate}
                                    onChange={toggleFbSynchroInvoiceOnUpdate}
                                    color="success"
                                />
                            }
                            label="A la mise à jour d'une facture"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mt: 2
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freshbooksSynchroInvoiceDeletion}
                                    onChange={toggleFbSynchroInvoiceOnDelete}
                                    color="success"
                                />
                            }
                            label="A la suppression d'une facture"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mt: 2
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freshbooksSynchroInvoicePayment}
                                    onChange={toggleFbSynchroInvoiceOnPayment}
                                    color="success"
                                />
                            }
                            label="Au paiement d'une facture"
                        />
                    </Box>
                </>
            }
        </Box>
    );
}

export default SettingsPage;