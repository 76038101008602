import { freshbooksActionsType } from '../../constants';

const initialState = {};
export const freshbooks = (state = initialState, action) => {
  switch (action.type) {
    case freshbooksActionsType.LIST_PRODUCTS:
      return {
        ...state,
        products: action.products
      };

    default:
      return state;
  }
}

