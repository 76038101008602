import React, { useEffect, useState } from "react";
import { Grid, Box, Paper, MenuItem, Select, InputLabel, FormControl, TextField } from "@mui/material";
import SummaryInvoices from "../components/dashboard/invoices/summaryInvoices";
import ListInvoices from "../components/dashboard/invoices/ListInvoices";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { TeamService } from "../services/teams/team.service";
import { CURRENT_USER } from "../constants";
import { jwtDecode } from "jwt-decode";
import ModelPieInvoice from "../components/dashboard/invoices/ModelPieInvoice";
import ModelBarInvoice from "../components/dashboard/invoices/ModelBarInvoice";
import { InvoiceStatisticService } from "../services/statistics/invoiceStatistic.service";

dayjs.locale('fr');

export default function InvoicesPage() {
    const [currentUser, setCurrentUser] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
    const today = dayjs();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [teams, setTeams] = useState([]);
    const [members, setMembers] = useState([]);
    const [cycleStageStats, setCycleStageStats] = useState([]);
    const [invoicesList, setInvoicesList] = useState([])

    const [teamId, setTeamId] = useState(currentUser.teamId || null);
    const [paymentMethod, setPaymentMethod] = useState('all');
    const [invoiceStatus, setInvoiceStatus] = useState('all');
    const [member, setMember] = useState(currentUser.idOwner || null);

    const [selectedPeriod, setSelectedPeriod] = useState('today');
    // const [, setMember] = useState(currentUser.idOwner || null);

    useEffect(() => {
        const token = localStorage.getItem('TOKEN_USER');
        if (token) {
            const user = jwtDecode(token);
            setCurrentUser(user);
            setTeamId(user.teamId);
        }
    }, []);

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleTeamChange = (event) => {
        setTeamId(event.target.value);
        setMember('');
    };

    const handleMemberChange = (event) => {
        setMember(event.target.value);
    };

    const handleInvoiceStatusChange = (event) => {
        setInvoiceStatus(event.target.value);
        if (event.target.value === 'unpaid') {
            setPaymentMethod('all'); 
        }
    }

    const handleInvoicePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    }

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);

        if (event.target.value !== 'period') {
            updateDateRange(event.target.value);
        }
    }

    useEffect(() => {
        if (teamId !== 'all') {
            const selectedTeam = teams.find(t => t.id === teamId);
            setMembers(selectedTeam ? selectedTeam.members : []);
        }
        else {
            const allMembers = teams.reduce((acc, team) => {
                return acc.concat(team.members);
            }, []);
            setMembers(allMembers);
        }
    }, [teamId, teams]);

    useEffect(() => {
        fetchInvoiceGlobalStat();
    }, [teamId, member, paymentMethod, invoiceStatus, startDate, endDate]);


    const fetchInvoiceGlobalStat = async () => {
        try {
            const allResult = InvoiceStatisticService.getInvoiceGlobalStatForDateRange(teamId, member, paymentMethod, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
            setInvoicesList((await allResult).invoiceList);
        } catch (error) {
            console.error("Error getGlobalStatForDateRange:", error);
        }
    };

    const fetchTeams = async () => {
        try {
            const fetchedTeams = await TeamService.getTeamsWithMembers();
            setTeams(fetchedTeams);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };

    useEffect(() => {
        updateDateRange(selectedPeriod);
    }, [selectedPeriod]);

    const updateDateRange = (period) => {
        let newStartDate, newEndDate;

        switch (period) {
            case 'today':
                newStartDate = today;
                newEndDate = today;
                break;
            case 'yesterday':
                newStartDate = today.subtract(1, 'day');
                newEndDate = today.subtract(1, 'day');
                break;
            case 'thisWeek':
                newStartDate = today.startOf('week');
                newEndDate = today.endOf('week');
                break;
            case 'lastWeek':
                newStartDate = today.subtract(1, 'week').startOf('week');
                newEndDate = today.subtract(1, 'week').endOf('week');
                break;
            case 'thisMonth':
                newStartDate = today.startOf('month');
                newEndDate = today.endOf('month');
                break;
            case 'lastMonth':
                newStartDate = today.subtract(1, 'month').startOf('month');
                newEndDate = today.subtract(1, 'month').endOf('month');
                break;
            default:
                newStartDate = today;
                newEndDate = today;
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    return (
        <Box sx={{ padding: 2 }}>
            {/* Filtre Section */}
            <Paper elevation={3} sx={{ marginBottom: 0, padding: 3 }}>
                <Grid container spacing={2}>
                    {/* Dropdown pour sélectionner une équipe */}
                    <Grid item xs={12} sm={1.7}>
                        <FormControl fullWidth>
                            <InputLabel id="team-label">Sélectionner une équipe</InputLabel>
                            <Select
                                labelId="team-label"
                                value={teamId}
                                label="Sélectionner une équipe"
                                onChange={handleTeamChange}
                                disabled={currentUser.profile !== 'SUPERADMIN'}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                {teams?.map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Dropdown pour sélectionner un membre */}
                    <Grid item xs={12} sm={1.7}>
                        <FormControl fullWidth>
                            <InputLabel id="member-label">Sélectionner un membre</InputLabel>
                            <Select
                                labelId="member-label"
                                value={member}
                                label="Sélectionner un membre"
                                onChange={handleMemberChange}
                                disabled={currentUser.profile === 'COMMERCIAL'}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                {members.map((member) => (
                                    <MenuItem key={member.id} value={member.idOwner}>
                                        {member.firstName} {member.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Dropdown pour sélectionner un statut de facture */}
                    <Grid item xs={12} sm={1.7}>
                        <FormControl fullWidth>
                            <InputLabel id="team-label">Statut de facture</InputLabel>
                            <Select
                                labelId="invoice-status-label"
                                value={invoiceStatus}
                                label="Sélectionner un statut"
                                onChange={handleInvoiceStatusChange}
                            >
                                <MenuItem value="all">Toutes</MenuItem>
                                <MenuItem value="paid">Payées</MenuItem>
                                <MenuItem value="unpaid">Impayées</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Dropdown pour sélectionner un statut de facture */}
                    <Grid item xs={12} sm={1.7}>
                        <FormControl fullWidth>
                            <InputLabel id="team-label">Mode de paiement</InputLabel>
                            <Select
                                labelId="payment-method-label"
                                value={paymentMethod}
                                label="Sélectionner un mode de paiement"
                                onChange={handleInvoicePaymentMethodChange}
                                disabled={invoiceStatus === 'unpaid'}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                <MenuItem value="Check">Par chèque</MenuItem>
                                <MenuItem value="Cash">Par espèce</MenuItem>
                                <MenuItem value="bank_transfer">Par virement bancaire</MenuItem>
                                <MenuItem value="Card">Par carte</MenuItem>
                                <MenuItem value="iban">Par RIB</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Nouveau filtre : Sélection de période */}
                    <Grid item xs={12} sm={1.7}>
                        <FormControl fullWidth>
                            <InputLabel id="period-label">Sélectionner une période</InputLabel>
                            <Select
                                labelId="period-label"
                                value={selectedPeriod}
                                label="Sélectionner une période"
                                onChange={handlePeriodChange}
                            >
                                <MenuItem value="period">Période</MenuItem>
                                <MenuItem value="today">Aujourd'hui</MenuItem>
                                <MenuItem value="yesterday">Hier</MenuItem>
                                <MenuItem value="thisWeek">Cette semaine</MenuItem>
                                <MenuItem value="lastWeek">Semaine dernière</MenuItem>
                                <MenuItem value="thisMonth">Ce mois</MenuItem>
                                <MenuItem value="lastMonth">Mois dernier</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* DatePicker pour la date de début */}
                    <Grid item xs={12} sm={1.7}>
                        <DatePicker
                            label="Date de début"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: '100%' }}
                            disabled={selectedPeriod !== 'period'}
                        />
                    </Grid>
                    {/* DatePicker pour la date de fin */}
                    <Grid item xs={12} sm={1.7}>
                        <DatePicker
                            label="Date de fin"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: '100%' }}
                            disabled={selectedPeriod !== 'period'}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* SummaryInvoices et Graphiques */}
            <SummaryInvoices invoicesList={invoicesList} />
            <ListInvoices 
                invoicesList={invoicesList} 
                invoiceStatus={invoiceStatus} 
                teamId={teamId}
                member={member}
                paymentMethod={paymentMethod}
                startDate={startDate}
                endDate={endDate}
            />

            <Grid container spacing={3} pl={0} pr={0}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <h3>CA équipe</h3>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="dropdown-label">Statut</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={invoiceStatus}
                                    onChange={handleInvoiceStatusChange}
                                    label="Choisir"
                                >
                                    <MenuItem value="all">Toutes</MenuItem>
                                    <MenuItem value="paid">Payées</MenuItem>
                                    <MenuItem value="unpaid">Impayées</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <ModelBarInvoice invoicesList={invoicesList} teamId={teamId} teams={teams} invoiceStatus={invoiceStatus} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Mode de paiement</h3>
                        <ModelPieInvoice invoicesList={invoicesList} />
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    );
}
