import axios from 'axios';
const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

export const getCoordinates = async (address) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`;

  try {
    const response = await axios.get(url);
    const features = response.data.features;

    if (features.length > 0) {
      const [lng, lat] = features[0].center;
      return { lat, lng };
    } else {
      throw new Error('No results found for the given address.');
    }
  } catch (error) {
    console.error('Failed to fetch coordinates:', error);
    throw error;
  }
};

export const fetchAddressFromCoordinates = async (lng, lat) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data && data.features && data.features.length > 0) {
      const place = data.features[0];
      const context = place.context || [];

      const company = place.text || 'N/A'; // Le nom de l'entité associée au lieu
      const address = place.place_name || 'N/A'; // Adresse complète

      const state = context.find(c => c.id.includes('place'))?.text || 'N/A';
      const city = context.find(c => c.id.includes('place'))?.text || 'N/A';
      const departement = context.find(c => c.id.includes('region'))?.text || 'N/A';
      const zip = context.find(c => c.id.includes('postcode'))?.text || 'N/A'; // Code postal
      const country = context.find(c => c.id.includes('country'))?.text || 'N/A';

      return {
        city,
        departement,
        state,
        country,
        company,
        address,
        zip
      };
    } else {
      throw new Error('No address found for the given coordinates');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getGeolocationError = (error) => {
  console.log("Error - MAP : ", error);
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return "Vous avez refusé la demande de géolocalisation. Veuillez l'activer.";
    case error.POSITION_UNAVAILABLE:
      return "Les informations de localisation sont indisponibles. Veuillez l'activer.";
    case error.TIMEOUT:
      return "La demande de géolocalisation a expiré. Veuillez réessayer.";
    default:
      return "Une erreur inconnue s'est produite.";
  }
};
