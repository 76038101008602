import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DashboardPage from "../pages/DashboardPage";
import MapPage from "../pages/MapPage";
import TeamManagementPage from "../pages/TeamManagementPage";
import FavoritesPage from "../pages/FavoritesPage";
import SettingsPage from "../pages/SettingsPage";
import InvoicesPage from '../pages/InvoicesPage';

const ContentContainer = styled("div")({
  //padding: "16px",
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Content = ({ selectedItem, darkMode, toggleDarkMode }) => {
  const [open, setOpen] = useState(false);

  const { alert, loading } = useSelector(state => state);

  useEffect(() => {
    setOpen(alert.message != null);
  }, [alert]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Dashboard":
        return <DashboardPage />;
      case "Map":
        return <MapPage darkMode={darkMode} />;
      case "TeamManagement":
        return <TeamManagementPage />;
      case "Favorites":
        return <FavoritesPage />;
      case "Invoices":
        return <InvoicesPage />;
      case "Settings":
        return <SettingsPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />;
      default:
        return <div>Select an item from the sidebar</div>;
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ margin: '10px' }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%', fontSize: '1rem' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <ContentContainer
        sx={{
          width: {
            xs: '100vw',
            sm: '100%'
          },
        }}
      >
        {renderContent()}
      </ContentContainer>
    </>
  )
}

export default Content;