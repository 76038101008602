import { commonService } from '../generic.service';

const createInvoiceForClient = async (client, products, paymentMode) => {
    const data = {
        client: {
            fbClientId: client.fbClientId,
            organization: client.companyName,
            email: client.email,
            fname: client.fname,
            lname: client.lname,
            phone: client.phone,
            home_phone: client.phone,
            id_client: client.id,
            p_code: client.company.zip || '',
            p_street: client.address || '',
            p_city: client.company.city || '',
            p_province: client.company.region || '',
            p_country: client.company.state || '',
        },
        products_details: products.items,
        cost: {
            netPrice: products.totalHorsTaxes,
            tax: products.montantTaxes
        },
        company: {
            restaurantName: client.restaurantName,
            fbHubspotCompanyId: client.fbHubspotCompanyId
        },
        totalTTC: products.totalTTC,
        paymentType: paymentMode
    }
    try {
        const response = await commonService.post(`freshbooks/clients`, data);
        if (!response) {
            throw new Error(`Erreur HTTP : ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        throw new Error(`Erreur lors de la recuperation de client ou de la creation de facture`);
    }

};

const getAllProducts = () => {
    const results = commonService.get(`freshbooks/products`);
    return results;
};

const getTaxesList = () => {
    const results = commonService.get(`freshbooks/taxes`);
    return results;
};

const getInvoiceListByCompanyId = (companyId) => {
    const invoices = commonService.get(`freshbooks/invoices/${companyId}`);
    return invoices;
}

const enableSynchro = (name) => {
    const data = {
        name
    }
    return commonService.post(`freshbooks/webhooks/register`, data);
}

const getCallbacksList = () => {
    return commonService.get(`freshbooks/webhooks/list`);
};

const deleteCallback = (id) => {
    return commonService.remove(`freshbooks/webhooks/${id}`);
};

const createPayment = (paymentType, invoiceId, totalTTC) => {
    const data = {
        invoiceid: invoiceId,
        totalTTC,
        paymentType
    }
    return commonService.post(`freshbooks/payment`, data);
}

export const FreshBooksService = {
    createInvoiceForClient,
    getAllProducts,
    getTaxesList,
    getInvoiceListByCompanyId,

    enableSynchro,
    getCallbacksList,
    deleteCallback,
    createPayment
};
